
import { defineComponent } from "vue";
import ListBase from "@/classes/ListBase.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {CounterpartyApi} from "@/api/counterparty.api";
import { SupportTeamApi } from "@/api/supportTeam.api";

export default defineComponent({
  mixins: [ListBase],
  components: {DeleteDialog},
  methods: {
    deleteContact() {
      console.log('BASE PAGE', this.basePage);
      if(this.basePage === 'counterparty') {
        CounterpartyApi.removeContactFromCounterParty(this.id)
          .then(() => {
            this.onChange(this.currentPage, this.currentSize)
          })
      } else {
        SupportTeamApi.deleteAdditionalContact(this.id)
          .then(()=>{
          this.onChange(this.currentPage, this.currentSize)
        })
      }
    },
    openDeleteDialog(id, type, val ) {
      console.log(id, ' a eto record')
      this.id = id;
      this.$refs.delete.openDialog("контакта", `${type}: ${val} `);
    }
  },
  mounted() {
    console.log(this.list,'eto list')
    console.log(this.filteredFields,'eto filteredFields')
  }
});
