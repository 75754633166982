
import { defineComponent } from "vue";
import { autocompletePlaceholder } from "@/consts/placeholders";
import { BehaviorSubject } from "rxjs";
import { getFieldSettings } from "@/consts/settings";
import {RolesApi} from "@/api/roles.api";

export default defineComponent({
  props: {
    body: null,
  },
  data: () => ({
    autocompletePlaceholder,
    data: {},
    dataTemp: {},

    exceptsKeys: [] as { key: string; field: string }[],
    validationFields: [],
    errors: [],
    errorsValidEmail: [],
    formTouched: false,
    isLoad: false,
    isWarning: false,
    customErrors: [],
    CRUDSettings: []
  }),
  mounted() {
    this.setBody();
  },
  watch: {
    body() {
      this.setBody();
    },
    data: {
        handler(){
            // if (!this.isLoad) {
            //     this.isLoad = true;
            //     this.cleanWarning();
            //     return;
            // }
            this.addWarning();
        },
        deep: true
    }
  },
  methods: {
    getFieldSettings,
    getCRUDSettings(id) {
      RolesApi.getFieldsCRUD(id)
          .then((res) => {
           this.CRUDSettings = res.fields
          })
    },
    hasAccessToRead(menuCode, childMenuCode, tabMenuCode?) {
      if( !menuCode) {
        return  true;
      }
      if ( childMenuCode) {
        if( tabMenuCode) {
          console.log(this.currentUser.value.access.items.find((res) => res.menuCode === menuCode)
            .children.find(res => res.menuCode === childMenuCode)
            .children.find(res => res.menuCode === tabMenuCode).read, 'PROVERKA')
          return this.currentUser.value.access.items.find((res) => res.menuCode === menuCode)
              .children.find(res => res.menuCode === childMenuCode)
              .children.find(res => res.menuCode === tabMenuCode).read &&
            this.currentUser.value.access.items.find((res) => res.menuCode === menuCode)
              .children.find(res => res.menuCode === childMenuCode).read
        }
        else  {
          return this.currentUser.value.access.items.find((res) => res.menuCode === menuCode).children.find(res => res.menuCode === childMenuCode).read
        }
      } else {
        return this.currentUser.value.access.items.find((res) => res.menuCode === menuCode).read
      }
    },
    hasAccessToSave(menuCode, childMenuCode, tabMenuCode?) {
      if ( childMenuCode) {
        if( tabMenuCode) {
          return !(this.currentUser.value.access.items.find((res) => res.menuCode === menuCode)
              .children.find(res => res.menuCode === childMenuCode)
              .children.find(res => res.menuCode === tabMenuCode).update &&
            this.currentUser.value.access.items.find((res) => res.menuCode === menuCode)
              .children.find(res => res.menuCode === childMenuCode).update)
        }
        else  {
          return this.currentUser.value.access.items.find((res) => res.menuCode === menuCode).children.find(res => res.menuCode === childMenuCode).update
        }
      } else {
        return this.currentUser.value.access.items.find((res) => res.menuCode === menuCode).update
      }
    },
    hasAccessToLink(menuCode, childMenuCode, tabMenuCodes = []) {
      const isAccessible = tabMenuCodes.some(tabMenuCode => {
        const tabMenu = this.currentUser.value.access.items.find(res => res.menuCode === menuCode)
          .children.find(res => res.menuCode === childMenuCode)
          .children.find(res => res.menuCode === tabMenuCode);

        console.log(tabMenu?.read, 'PROVERKA');

        return tabMenu?.read;
      });

      const isChildMenuReadable = this.currentUser.value.access.items.find(res => res.menuCode === menuCode)
        .children.find(res => res.menuCode === childMenuCode)
        .read;

      return isAccessible && isChildMenuReadable;
    },
    getFieldCRUDSettingsRead(id) {
      if (this.CRUDSettings.length > 0) {
        return this.CRUDSettings.find((res) => res.id == id).read
      }
    },
    getFieldCRUDSettingsUpdate(id) {
      if (this.CRUDSettings.length > 0) {
        return !this.CRUDSettings.find((res) => res.id == id).update
      }
    },
    addWarning() {
       window.onbeforeunload = function() { return "Message"; };
       this.isWarning = true;
    },
    cleanWarning(){
        window.onbeforeunload = undefined;
        console.log("test cleanWarning");
        this.isWarning = false;
    },
    validateEmail(email) {
      let pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return pattern.test(email);
    },
    getDataCharacteristics() {
      return this.data;
    },
    formatCharacteristics() {
      const characteristics = this.data;
      Object.keys(this.data).forEach((key) => {
        this.exceptsKeys.forEach((except) => {
          if (key === except.field && !!this.data[key][except.key]) {
            return;
          }
        });
        if (
            typeof this.data[key] === "object" &&
            !this.data[key]?.toISOString &&
            !this.data[key]?.id
        ) {
          characteristics[key] = null;
        }
      });
      return characteristics;
    },
    formatCharacteristicsCopy() {
      const characteristics = JSON.parse(JSON.stringify(this.data));
      Object.keys(this.data).forEach((key) => {
        this.exceptsKeys.forEach((except) => {
          if (key === except.field && !!this.data[key][except.key]) {
            return;
          }
        });
        if (
            typeof this.data[key] === "object" &&
            !this.data[key]?.toISOString &&
            !this.data[key]?.id
        ) {
          characteristics[key] = null;
        }
      });
      return characteristics;
    },
    hasErrors(field = "", errors = this.errors) {
      return errors.map((error, i) => {
        return error.field === field ? { index: i, error } : false;
      })[0];
    },
    hasInValidationFields(comparedField) {
      return this.validationFields.find(({field}) => comparedField === field);
    },
    validateField(field = "", event?, validationField?) {
      if(this.customValidation(field, event, validationField)) {
        return
      }

      let data;

      if (!validationField) {
        validationField = this.validationFields.find((_) => _.field === field);
      }

      if (!validationField) {
        return;
      }

      if (event && event.target) {
        data = event.target.value;
      }
      if (typeof event === "string" || typeof event === "number") {
        data = event;
      }
      if (event === null || event === undefined) {
        if (validationField.field.includes(".")) {
          data = this.data;
          validationField.field.split(".").forEach((_) => {
            data = data[_];
          });
        } else {
          data = this.data[field];
        }
      }
      const error = this.hasErrors(field);
      if (validationField.validators.includes("required") && !data && error) {
        if (this.formTouched) {
          this.setHasErrorClass(field);
        }
        return;
      }
      if (validationField.validators.includes("required") && !data && !error) {
        if (this.formTouched) {
          this.setHasErrorClass(field);
        }
        this.errors.push({ touched: false, ...validationField });
        return;
      }
      if (validationField.validators.includes("email") && data && !this.validateEmail(data)) {
        if (this.formTouched) {
          this.setHasErrorClass(field);
        }
        this.errorsValidEmail.push({ touched: false, ...validationField });
        return;
      }
      if (validationField.validators.includes("ipaddress") && data && !this.validateIpAddress(data)) {
        if (this.formTouched) {
          this.setHasErrorClass(field);
        }
        this.errorsValidEmail.push({ touched: false, ...validationField });
        return;
      }
      this.removeHasErrorClass(field);
      this.errors = this.errors.filter((error) => error.field !== field);
    },
    customValidation(field = "", event?, validationField?) {
      return false;
    },
    validate(validationFields = this.validationFields): boolean | any[] {
      this.formTouched = true;
      this.errors = [];
      this.errorsValidEmail = [];
      validationFields.forEach((field) => {
        this.validateField(field.field);
      });
      if (this.errors.length) {
        this.$notification.open({
          message: "Заполните обязательные поля",
          style: {
            width: "330px",
            marginLeft: `${400 - 330}px`,
            fontSize: "14px",
          },
          placement: 'bottomRight',
          duration: 3,
        });
      }
      if (this.errorsValidEmail.length) {
        this.$notification.open({
          message: "Адрес электронной почты должен вводиться латинскими буквами, " +
              "цифрами и символами и соответствовать формату: abc@abc.ru",
          style: {
            width: "380px",
            marginLeft: `${800 - 780}px`,
            fontSize: "14px",
          },
          placement: 'bottomRight',
          duration: 3,
        });
      }
      return !this.errors.length && !this.errorsValidEmail.length && !this.customErrors.length ? false : this.errors;
    },
    setHasErrorClass(ref) {
      if(this.$refs[ref] instanceof Array) {
        this.$refs[ref].forEach(({$el}) => {
          if(!$el) {
            return;
          }
          $el.parentNode.classList.add("has-error")
        });
        return;
      }
      this.$refs[ref]?.$el.parentNode.classList.add("has-error");
    },
    removeHasErrorClass(ref) {
      if (this.$refs[ref] instanceof Array) {
            this.$refs[ref].forEach((el, ind) => {
                this.$refs[ref][ind].$el.parentNode.classList.remove("has-error");
            });
            return;
        }
      this.$refs[ref]?.$el.parentNode.classList.remove("has-error");
    },
    setBody() {
      if (!this.$props.body) {
        return;
      }
      Object.keys(this.$props.body).forEach((key) => {
        this.exceptsKeys.forEach((except) => {
          if (
              except.key === key &&
              !!this.$props.body[key] &&
              !!this.$props.body[key][except.field]
          ) {
            this.data[key] = this.$props.body[key];
            return;
          } else if (
              except.key === key &&
              (!this.$props.body[key] || !this.$props.body[key][except.field])
          ) {
            return;
          }
        });
        if (typeof this.data[key] === "object" && !this.$props.body[key]) {
          return;
        }
        this.data[key] = this.$props.body[key];
      });
    },
    onSelect(value, option) {
      const key = "id";
      this.data[option.field][key] = option[key];
    },
    changeAutocomplete(name, event?, value?){
      if(!event) {
        this.data[name] = value ? value : {id: null, name: ""};
      }
    },
    refreshAutocomplete(event, func){
      if(!event) {
        func();
      }
    },
    isNumber(event) {
      console.log(event)
      if (event.charCode === 0 || /\d/.test(String.fromCharCode(event.charCode))) {
        return true
      } else {
        event.preventDefault();
      }
    },
    isTelephoneNumber(event) {
      if (event.charCode === 0 || event.charCode === 43 || /\d/.test(String.fromCharCode(event.charCode))) {
        return true
      } else {
        event.preventDefault();
      }
    },
    isNumberWithDot(event) {
      console.log(event);
      if (event.charCode === 0 || event.charCode === 46 || /\d/.test(String.fromCharCode(event.charCode))) {
        return true
      } else {
        event.preventDefault();
      }
    },
    onPaste(event) {
      if (event.charCode === 0 || /\d/.test(String.fromCharCode(event.charCode))) {
        return true
      } else {
        event.preventDefault();
      }
    },
    onPasteTelephone(event) {
      if(event.type === 'paste') {
        let checkValue = event.clipboardData.getData("text").match(/[A-zА-я._%-]/g);
        if (checkValue) {
          event.preventDefault();
        } else {
          return true
        }

      }
      else {
        event.preventDefault();
      }

    },
    ipAddress(event) {
      const x = event.target.value.replace(/\D/g, '').match(/(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})/);
      let t1 = x[1] ? x[1] + '.' : '';
      let t2 = x[2] ? x[2] + '.' : '';
      let t3 = x[3] ? x[3] + '.' : '';
      let t4 = x[4] ? x[4] : '';
      event.target.value = t1 + t2 + t3 + t4;
    },
    validateIpAddress(value) {
      let pattern = /\d{0,4}\.\d{0,4}\.\d{0,4}\.\d{0,4}/;
      let match = value.match(pattern);
      let v = match ? match[0] : null;
      return pattern.test(value) && value === v;
    },
  },
});
