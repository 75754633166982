
import {Util} from "@/components/utils/Util";
import moment from "moment";
import {defineComponent} from "vue";

export default defineComponent({
  props: {
    visible: {
      default: false
    },
  },
  watch: {
    visible() {
      this.isVisible = this.$props.visible;
    }
  },
  data: () => ({
    isVisible: false,
    filter: [],
    valuesForCheckboxes: [],
  }),
  methods: {
    etQueryValFromArray(query, name, arr) {
      if(query[name]) {
        this.valuesForCheckboxes[arr] = query[name] instanceof Array
            ? query[name].map((status) => Number(status))
            : [+query[name]];
      }
    },

    isExcepted(excepts, key) {
      if (!excepts) {
        return false;
      }
      return excepts.find((except) => except.field === key) || false;
    },

    formatValueFromCheckboxes(
        excepts?: { field: string; value: unknown; condition?: (v) => unknown }[]
    ) {
      return Object.keys(this.valuesForCheckboxes).map((key) => {
        const except = this.isExcepted(excepts, key);
        if (
            !!this.valuesForCheckboxes[key].length &&
            this.valuesForCheckboxes[key][0] !== ""
        ) {
          this.filter[key.replace("Arr", "")] = except?.value
              ? except.value(this.valuesForCheckboxes[key])
              : this.valuesForCheckboxes[key];
          return;
        }

        if (
            !!key.match(/Arr/i) &&
            !!this.valuesForCheckboxes[key].length &&
            this.valuesForCheckboxes[key][0] === ""
        ) {
          if (!except) {
            this.filter[key.replace("Arr", "")] = [];
            return;
          }
          console.log(except);
          this.filter[key.replace("Arr", "")] = except.value;
          return;
        }
      });
    },

    onCheckLastValue(array, name) {
      // only last value
      Util.onCheckLastValue(array, name);
    },
    onCheckManyValues(array, name) {
      // many values
      Util.onCheckManyValues(array, name);
    },
    // To filter from query:
    setFilterFromQueryParam(query, names: [string]){
      names.forEach((name) => {
        if(query[name]) {
          this.filter[name] = query[name];
        }
      });
    },
    setFilterFromQueryDate(query, name){
      if(query[name]) {
        this.filter[name] = moment(query[name]).format('MM.DD.YYYY');
      }
    },
    // end
    // To query from filter:
    setQueryFromFilterParam(queryParams, names: [string]) {
      names.forEach((name) => {
        if (this.filter[name]) {
          queryParams[name] = this.filter[name];
        }
      });
    },
    setQueryFromFilterDate(queryParams, name){
      if(this.filter[name]) {
        queryParams[name] = moment(this.filter[name]).format('DD.MM.YYYY');
      }
    },
    setQueryParamsArrDefault(queryParams, excepts : [string]) {
      Object.keys(this.valuesForCheckboxes)
          .filter((v) => v.includes('Arr') && !excepts.includes(v))
          .forEach((arr) => {
            if (this.valuesForCheckboxes[arr].length == 1
                && this.valuesForCheckboxes[arr][0] === '') {
              delete queryParams[arr.slice(0, -3)];
            } else {
              const val = this.valuesForCheckboxes[arr].join('%');
              queryParams[arr.slice(0, -3)] = val;
            }
          });
    },
    // end
    // setCheckboxesArrFromQuery(query, names: [string]) { // to Arr
    //   names.forEach((name) => {
    //     let arr = name + 'Arr';
    //     if (query[name]) {
    //       this.valuesForCheckboxes[arr] = query[name] instanceof Array
    //           ? query[name].map((status) => Number(status))
    //           : [+query[name]];
    //     }
    //   });
    // }
    setCheckboxesArrFromQuery(query, names: [string]) { // to Arr
      names.forEach((name) => {
        let arr = name + 'Arr';
        if (query[name]) {
          let val = query[name].split('%');
          this.valuesForCheckboxes[arr] = val instanceof Array
              ? val.map((status) => Number(status)) : [+val];
        }
      });
    },
    setCheckboxesArrFromQueryString(query, names: [string]) { // to Arr
      names.forEach((name) => {
        let arr = name + 'Arr';
        if (query[name]) {
          let val = query[name].split('%');
          this.valuesForCheckboxes[arr] = val instanceof Array ? val : [val];
        }
      });
    },
    // default values checkboxes Arr
    defaultValueCheckboxes(excepts : [string]) { // Default value - ['']
      Object.keys(this.valuesForCheckboxes)
          .filter((v) => v.includes('Arr') && !excepts.includes(v))
          .forEach((arr) => {
            this.valuesForCheckboxes[arr] = [''];
          })
    },
    applyFilterBtn() {
      this.applyFilter();
      this.$emit('update:visible', false);
    },
    filterChange(filterImpClass) {
      const filterImp = filterImpClass;
      let result = false;
      Object.keys(this.filter).map((key) => {
        if (this.filter[key] instanceof Array) {
          const arrParse = JSON.parse(JSON.stringify(this.filter[key]));
          const filterStr = JSON.stringify(arrParse);
          const filterImpStr = JSON.stringify(filterImp[key]);
          if (filterStr != filterImpStr) {
            result = true;
          }
        } else {
          const filterStr = this.filter[key];
          const filterImpStr = filterImp[key];
          if ((filterStr !== undefined && filterImpStr !== undefined)
              && (filterStr != filterImpStr)) {
            result = true;
          }
        }
      });

      this.$emit("filterChanged", result);
    }
  }
})
