
import {defineComponent} from "vue";
import {takeUntil, tap} from "rxjs/operators";
import {Subject} from "rxjs"
import {debounceTime, switchMap} from 'rxjs';
import {currentUser} from "@/consts/currentUser";

export default  defineComponent({
  data: () => ({
    applyFilterRequest$: new Subject(),
    subject$: new Subject(),
    page: 1,
    size: 20,
    listPage: 1,
    listSize: 20,
    list: [],
    isLoad: false,
    countForList: 20,
    prepareData: null,
    isFirstLoaded: false,
    filter: null,
    isFilterChanged: false,
    currentFilter: 'filter' as 'filter' | 'containsValue',
    currentUser
  }),
  methods: {
    back() {
      this.$router.go(-1);
    },
    hasAccessToCreate(menuCode, childMenuCode) {
      if ( childMenuCode) {
        return this.currentUser.value.access.items.find((res) => res.menuCode === menuCode).children.find(res => res.menuCode === childMenuCode).create
      } else {
        return this.currentUser.value.access.items.find((res) => res.menuCode === menuCode).create
      }
    },
    hasAccessToRead(menuCode, childMenuCode) {
      if ( childMenuCode) {
        return this.currentUser.value.access.items.find((res) => res.menuCode === menuCode).children.find(res => res.menuCode === childMenuCode).read
      } else {
        return this.currentUser.value.access.items.find((res) => res.menuCode === menuCode).read
      }
    },
    hasAccessToDelete(menuCode, childMenuCode) {
      if ( childMenuCode) {
        return this.currentUser.value.access.items.find((res) => res.menuCode === menuCode).children.find(res => res.menuCode === childMenuCode).delete
      } else {
        return this.currentUser.value.access.items.find((res) => res.menuCode === menuCode).delete
      }
    },
    getSelectedListObjects(e) {
      this.selectedList = e;
    },
    searchMethod(_) {
      return _;
    },
    resultMethod(value){
      return value;
    },
    prepareDataForList() {
      if(this.prepareData != null) {
        for (let i = 0; i < this.countForList; i++) {
          this.list.push(this.prepareData);
        }
      }
    },
    updatePage({page, size}) {
      if (this.size !== size) {
        this.page = 0;
        this.listPage = 1;
      } else {
        this.page = page - 1;
        this.listPage = page;
      }

      this.size = size;
      this.applyFilter();
    },
    applyFilter(from?: 'filter' | unknown){
      return null;
    },
    filterUpdate(filter) {
      if(!this.$route.query.realtedId) {
        filter[this.exceptKey] = this.$route.query.realtedId;
      }
      this.filter = Object.assign({}, filter);
      if(!this.isFirstLoaded && this.search) {
        this.isFirstLoaded = true;
        return;
      }
      this.applyFilter('filter');
    },
    commonSearch() {
      this.page = 0;
      this.listPage = 1;
      this.applyFilterRequest$.next('containsValue');
    },
    filterChanged(changed) {
      this.isFilterChanged = changed;
    }
  },
  mounted() {
    this.prepareDataForList();
    if(this.$route.query.quickSearch) {
      this.search = this.$route.query.quickSearch;
      this.applyFilterRequest$.next('containsValue');
    }
    const applyFilter$ = this.applyFilterRequest$.pipe(
        tap(() => this.isLoad = false),
        debounceTime(300),
        switchMap((_) => {
          if(_) {
            this.currentFilter = 'containsValue';
          }else {
            this.currentFilter = 'filter';
          }
          return this.searchMethod(_);
        })
    );
    applyFilter$.pipe(takeUntil(this.subject$))
        .subscribe((res) => {
          this.resultMethod(res);
        });
  },
  beforeUnmount() {
    this.subject$.next();
    this.subject$.complete();
  },
  computed: {
    defineFilterData() {
      if(this.currentFilter === 'containsValue') {
        return {containsValue: this.search, sortParams: this.filter.sortParams}
      }
      return this.filter;
    },
    exceptKey() {
      if(!this.$route.query.from) {
        return;
      }
      if(this.$route.query.from === 'metric-group') {
        return 'exceptMetricGroupId'
      }
      if(this.$route.query.from === 'area') {
        return 'exceptAreaId'
      }
      if(this.$route.query.from === 'user') {
        return 'exceptUserId'
      }
      if(this.$route.query.from === 'group') {
        return 'exceptGroupId'
      }
      if(this.$route.query.from === 'role') {
        return 'exceptRoleId'
      }
      if(this.$route.query.from === 'metric-indicator') {
        return 'exceptIndicatorId'
      }
      if(this.$route.query.from === 'metric') {
        return 'exceptMetricId'
      }
      if(this.$route.query.from === 'service') {
        return 'exceptMonitoringObjectId'
      }
      if(this.$route.query.from === 'contract') {
        return 'exceptContractId'
      }
      if(this.$route.query.from === 'department') {
        return 'exceptDepartmentId'
      }
      if(this.$route.query.from === 'monitoring-object') {
        return 'exceptMonitoringObjectId'
      }
      if(this.$route.query.from === 'support-teams') {
        return 'exceptSupportTeamId'
      }
      if(this.$route.query.from === 'equipment-types') {
        return 'exceptMonitoringObjectTypeId'
      }
      if(this.$route.query.from === 'notification') {
        console.log(213412412)
        return 'exceptMailNotification'
      }
      return 'exceptServiceId'
    },
    searchKey() {
      if(this.$route.query.skey === 'area') {
        return 'exceptAreaId'
      }
      if(this.$route.query.skey === 'roles') {
        return 'exceptUserId'
      }
      if(this.$route.query.skey === 'groups') {
        return 'exceptUserId'
      }
      return ''
    },
  },
  watch: {
    search: {
      handler() {
        this.$router.replace({query: {
            ...this.$route.query,
            quickSearch: this.search
          }})
        if(!this.search) {
          this.applyFilter();
          return;
        }
        this.commonSearch();
      },
      // deep: true
    }
  },
})
