
import {defineComponent, PropType} from "vue";
import FilterBase from "@/classes/FilterBase.vue";
import {Field} from "@/types/fields";
import {MeasureFilterImps} from "@/components/classes/MeasureFilterImps";
import {CollectionAgentsImp} from "@/components/classes/CollectionAgentsImp";
import {MetricGroupApi} from "@/api/metric-group.api";
import {Util} from "@/components/utils/Util";
import {ClassifilesApi} from "@/api/classifires.api";
import Accordion from "@/components/ui/Accordion.vue";

export default defineComponent({
  components: {Accordion},
  mixins: [FilterBase],
  props: {
    fields: Object as PropType<Field<unknown>[]>
  },
  watch: {
    fields: {
      handler() {
        this.setFilterFields();
        this.applyFilter('filter');
      },
      deep: true
    }
  },
  methods: {
    changeFilter() {
      this.setQueryParams();
    },
    onCheckManyValues(array, name) {
      Util.onCheckManyValues(array, name);
    },
    applyFilter() {
      this.filter.measureTypeId =
          this.valuesForCheckboxes.measureArr.length === 1 &&
          this.valuesForCheckboxes.measureArr[0] === ""
              ? ''
              : this.valuesForCheckboxes.measureArr[0];
      this.filterChange(new MeasureFilterImps());
      this.$emit("filter", this.filter);
    },
    setQueryParams() {
      if (!this.$route.query.isRelated) {
        let queryParams: any = {};
        this.setQueryFromFilterParam(queryParams, ['name', 'description', "measureTypeId" ]);
        this.setQueryParamsArrDefault(queryParams, ["scale"]);
        this.$router.push({query: queryParams});
      }
    },
    getFieldsVisible(name) {
      let result = this.fields.find(obj => {
        return obj.field === name && obj.value === true;
      });
      return result;
    },
    getQueryParams(){
      const query = this.$route.query;
      this.setFilterFromQueryParam(query, ['name', 'description', "measureTypeId" , "scale" ]);
      this.setCheckboxesArrFromQuery(query, ["scale"]);
    },
    getMetricGroups(metricGroupName?) {
      const arrChecked = [];
      if (this.valuesForCheckboxes.measureArr.length > 0) {
        this.valuesForCheckboxes.measureArr.forEach(id => {
          const checked = this.valuesForCheckboxes.measureType.filter(mg => mg.id === id);
          if (checked.length > 0) {
            arrChecked.push(checked[0]);
          }
        });
      }
      const name = metricGroupName || this.measureType;
      ClassifilesApi.getMeasureType().then((res) => {
        this.valuesForCheckboxes.measureType = Util.transformToCheckboxes(res);
        if (arrChecked.length > 0) {
          arrChecked.forEach(c => {
            if (!this.valuesForCheckboxes.measureType.some(mg => mg.id === c.id)) {
              this.valuesForCheckboxes.measureType.push(c);
            }
          });
        }
      });
    },
    cleanFilter() {
      this.filter = new MeasureFilterImps();
      this.setFilterFields();
      this.filterChange(new MeasureFilterImps());
      this.valuesForCheckboxes.measureArr = [""];
      this.$emit("filter", this.filter);
    },
    setFilterFields() {
      this.fields.filter((v) => v.value === false)
          .forEach(f => {
            if(f.field === 'name') {
              this.filter.name = '';
            }
          });
    },
  },
  data: () => ({
    filter: new MeasureFilterImps(),
    filterData: {},
    measureType: '',
    valuesForCheckboxes: {
      measureType: [''],
      measureArr: [""],
    }
  }),
  mounted() {
    this.getQueryParams();
    this.setFilterFields();
    this.applyFilter();
    this.getMetricGroups();
  }
})
