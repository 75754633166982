
import {defineComponent, reactive} from "vue";
import moment from "moment";
import {ClickhouseApi} from "@/api/clickhouse";
import Graphic from "@/components/base/Graphic.vue";
import RangePeriod from "@/classes/RangePeriod.vue";
type Period = "day" | "month" | "week" | "year" | "custom";

export default defineComponent({
  components: {Graphic},
  mixins: [RangePeriod],
  data: () => ({
    sourceMetrics: [],
    metrics: [],
    metricsData: [],
    activeKeysForMonitoring: [],
    metricNameSearchValue: "",
    selectedMetrics: [],
    metricFromQueryIds: [],
    isLoadingGraphic: false,
  }),
  methods: {
    setDefaultMetrics() {
      if(!!this.$route.query.metricIds && JSON.parse(this.$route.query.metricIds)?.length) {
        this.metricFromQueryIds = JSON.parse(this.$route.query.metricIds);
        this.selectedMetrics = this.metrics.reduce(
          (prev, current) =>
            prev.concat(current.metrics).concat(current.indicators),
          []
        ).filter(metric => this.metricFromQueryIds.includes(metric?.id));
        this.metrics.forEach(metric => {
          metric.indicators?.forEach(indicator => {
            if(this.metricFromQueryIds.includes(indicator.id)) {
              this.activeKeysForMonitoring.push(metric.id);
            }
          })
          metric.metrics?.forEach(indicator => {
            if(this.metricFromQueryIds.includes(indicator.id)) {
              this.activeKeysForMonitoring.push(metric.id);
            }
          })
        })
        this.selectedMetrics.forEach(metric => {
          if(metric.instanceId) {
            this.getIndicatorData(metric)
            return;
          }
          this.getMonitoringData(metric);
        })
        console.log('return')
        return;
      };
      this.getDefMetric();
      if(this.selectedMetrics[0]) {
        if(this.selectedMetrics[0].instanceId) {
          this.getIndicatorData(this.selectedMetrics[0])
          return;
        }
        this.getMonitoringData(this.selectedMetrics[0]);
      }
    },
    highlightMatch(value: string, searchValue:string): string {
      const searchValueRegexp = new RegExp(`(${searchValue})`, "ig");
      if (value.search(searchValueRegexp) !== -1) {
        return value.replace(searchValueRegexp, `<span style="  background-color: #FFFF00">$1</span>`);
      }
      return value;
    },
    getMonitoringData({ id, name, instanceName,monitoringObjectId }) {
      this.isLoadingGraphic = true;
      if (instanceName) {
        ClickhouseApi.getIndicatorData(
            instanceName,
            "day",
            this.period[0].format("DD.MM.yyyy HH:mm:ss"),
            this.period[1].format("DD.MM.yyyy HH:mm:ss"),
            ""
        ).then((res) => this.computeMonitoringData({ res, id, name }));
        return;
      }
      ClickhouseApi.getMonitoringData(
          id,
          "day",
          this.period[0].format("DD.MM.yyyy HH:mm:ss"),
          this.period[1].format("DD.MM.yyyy HH:mm:ss"),
          monitoringObjectId.toString()
      ).then((res) => this.computeMonitoringData({ res, id, name }));
    },
    getIndicatorData({id, name, instanceName}) {
      ClickhouseApi.getIndicatorData(
          instanceName,
        "day",
        this.period[0].format("DD.MM.yyyy HH:mm:ss"),
        this.period[1].format("DD.MM.yyyy HH:mm:ss"),
        ""
      ).then((res) => this.computeMonitoringData({ res, id, name }))
    },
    getDefMetric() {

      this.selectedMetrics.push(
          this.sourceMetrics[0].metrics[0]
      );
      this.metrics[0].isActive = true
      console.log(this.metrics)
    },
    computeMonitoringData({res,id,name}) {
      let index;
      this.metricsData.forEach((m, i) => {
        if(m.id === id) {
          index = i;
        }
      });
      if(!!index || index === 0) {
        this.metricsData[index] = {id, name, source:res};
      }else {
        this.metricsData.push({id, name, source:res});
      }

      if(this.activeKey == "3") {
        this.$nextTick(() => {
          this.$refs.graphic.updateSeries(this.metricsData, !this.selectedPeriod)
        })
      }
      this.isLoadingGraphic = false;
    },
    hasInSelectedMetric(metric) {
      return !!this.selectedMetrics.find(m => m?.id === metric?.id);
    },
    selectMetric(value, metric) {
      if (!value) {
        this.selectedMetrics = this.selectedMetrics.filter(
            (selectedMetric) => metric?.id !== selectedMetric?.id
        );
        this.metricsData = this.metricsData.filter(m => m.id !== metric.id);
        if(this.activeKey === "3") {
          this.$nextTick(() => {
            /*TODO:  в Graphic обрабатывать случай, когда не нужно перерисовывать точки уже сгенерированных графиков*/
            this.$refs.graphic.updateSeries(this.metricsData)
          })
        }
        return;
      }
      this.selectedMetrics.push(metric);
      if(metric.instanceName) {
        this.getIndicatorData(metric)
        return;
      }
      this.getMonitoringData(metric);
    },
    searchMetrics(metricNameSearchValue) {
      this.activeKeysForMonitoring = [];
      const sourceMetrics = this.sourceMetrics.concat([])
      const searchValueRegexp = new RegExp(`(${metricNameSearchValue})`, "ig");
      this.metrics = sourceMetrics.map(metric => {
        const metrics = metric.metrics.filter(indicator => indicator.name.search(searchValueRegexp) !== -1);
        const indicators = metric.indicators.filter(indicator => indicator.name.search(searchValueRegexp) !== -1);
        if(!!metrics.length || !!indicators.length) {
          console.log(metric)
          this.activeKeysForMonitoring.push(metric.id)
          // this.activeKeysForMonitoring.push(indicators.map(res => res.id))
          console.log(this.activeKeysForMonitoring)
          return {...metric, metrics, indicators};
        }
        return false
      }).filter(_ => !!_);
    },
    handleTabChange(key) {
      if(key == "3") {
        this.$nextTick(() => {
          this.$refs.graphic.updateSeries(this.metricsData)
        })
      }
    },
    formatDateTimestamp(timestamp) {
      return moment(timestamp).format('DD.MM.yyyy HH:mm')
    }
  },
  watch: {
    period: {
      handler(val) {
        if(!val.length) {
          return;
        }
        this.selectedMetrics.forEach(metric => {
          if(metric.instanceId) {
            this.getIndicatorData(metric)
            return;
          }
          this.getMonitoringData(metric)
        });
        this.$router.replace({query: {
            ...this.$route.query,
            dateStart: moment(val[0]).valueOf(),
            dateEnd: moment(val[1]).valueOf(),
          }});

        if(!this.selectedMetrics.length) {
          return;
        }
      },
      deep: true
    },
    selectedMetrics: {
      handler(val) {
        console.log(val);
        this.$router.replace({query: {
            ...this.$route.query,
            metricIds: JSON.stringify(val.filter(_ => _?.id).map(_ => _?.id))
          }});
      },
      deep: true
    }
  }
})
