
import {defineComponent} from "vue";
import CharacteristicBase from "../../classes/CharacteristicBase.vue";
import {getMetricTypeData} from "@/consts/fields/metrics.fields";
import {ClassifilesApi} from "@/api/classifires.api";

export default defineComponent({
  name: "MetricsTypeCharacteristics",
  mixins: [CharacteristicBase],
  data: () => ({
    data: getMetricTypeData(),
    measureList: [],
    displayUnitList:[],
    monitoringSystemList: [],
    oidSnmpList: [],
    snmpListTotalCount: 0,
    snmpListCurrentSize: 25,
    metricTypeList: [],
    aggregationList: [{name: 'avg', id: 1}, {name: 'min', id: 2}],
    validationFields:[
      {field: "name", validators: ["required"]},
      {field: "measure.name", validators: ["required"]},
    ],
  }),
  mounted() {
    this.getMeasure();
    this.getMonitoringSystem();
    this.getCRUDSettings(77);
    this.getSnmpOidList();
  },
  methods: {
    getMeasure() {
      ClassifilesApi.getMeasure(this.data.measure.name)
          .then((res) => {
            this.measureList = res.data;
            this.displayUnitList = res.data;
          });
    },
    getMonitoringSystem() {
      ClassifilesApi.getMonitoringSystem()
          .then((res) => {
            this.monitoringSystemList = res;
          });
    },
    cleanAutocomplete(option, event?) {
      if(!event) {
        this.cleanSelect(option);
      }
    },
    getSnmpOidList() {
      ClassifilesApi.getSnmpOid(this.data.snmpOid.name, this.snmpListCurrentSize)
        .then((res) => {
          this.oidSnmpList = res.data;
          this.snmpListTotalCount = res.totalCount
        })
    },
    loadMoreSnmp() {
      this.snmpListCurrentSize += 25;
      this.getSnmpOidList();
    },
    selectMeasure(value, option) {
      this.data.measure.id = option.id
    },
    selectDisplayUnit(value, option) {
      this.data.displayUnit.id = option.id
    },
    selectMonitoringSystem(value, option) {
      this.onSelect(value, option);
    },
    onSelect(value, option) {
      if (option.field === "aggrType") {
        this.data[option.field] = value;
      }
      if(option.field === "metricCategory") {
        this.data.metricCategory.id = option.id;
      }
      if(option.field === "snmpOid") {
        this.data.snmpOid.id = option.id;
      }
      else {
        let key = "id";
        this.data[option.field] = option[key];
      }
    },
  }
})
