import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";

import "primeflex/primeflex.css";
import moment from "moment";

import Antd from "ant-design-vue";
import "./antd-variables.less";
// import Keycloak from "keycloak-js";

import AxiosInterceptor from "./interseptors/axios-interceptor";
import AxiosInterceptorError from "./interseptors/axios-interceptor-error";

import {use} from "echarts/core";
import {CanvasRenderer} from "echarts/renderers";
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
    ToolboxComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
} from "echarts/components";
import {LineChart, PieChart, BarChart} from "echarts/charts";
// import {CommonApi} from "@/api/common.api";


import 'moment/locale/ru'
import {AuthService} from "@/api/auth";
import {currentUser} from "@/consts/currentUser";
// import {AuthService} from "@/api/auth";
// import {currentUser} from "@/consts/currentUser";
moment.locale('ru')

use([
    CanvasRenderer,
    PieChart,
    LineChart,
    GridComponent,
    TitleComponent,
    TooltipComponent,
    ToolboxComponent,
    DataZoomComponent,
    LegendComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    BarChart
]);

// export let keycloak = null;
// export let initOptions = null;

// initializeKeycloak().then((res) => {
//     keycloak = res;

// keycloak
//     .init(initOptions)
//     .then((auth) => {
//         if (!auth) {
//             window.location.reload();
//         } else {
//             console.info("Authenticated");

const app = createApp(App);
moment.locale("ru");
app.use(Antd);

// app.component("Keycloak", Keycloak);

AxiosInterceptor();
AxiosInterceptorError();

app.use(router).mount("#app");

// eslint-disable-next-line @typescript-eslint/no-var-requires
const jwt = require('jsonwebtoken');
const token = localStorage.getItem('auth_token');

try {
    const decodedToken = jwt.decode(token, { complete: true });

    const currentTimestamp = Math.floor(Date.now() / 1000);

    if (decodedToken && decodedToken.payload && decodedToken.payload.exp <= currentTimestamp) {
        localStorage.removeItem('auth_token')
        router.push({
            path: "/login",
            query: {
                redirectUrl: window.location.pathname
            }
        });
    } else {
        AuthService.getCurrentUser().then(res => {
            currentUser.next(res);
        });
    }
} catch (error) {
    console.error('Ошибка при декодировании токена:', error.message);
}

// AuthService.getCurrentUser().then(res => {
//     currentUser.next(res);
// });
// }

//Token Refresh
// setInterval(() => {
//     keycloak
//         .updateToken(70)
//         .then((refreshed) => {
//             if (refreshed) {
//                 console.info("Token refreshed" + refreshed);
//             } else {
//                 console.warn(
//                     "Token not refreshed, valid for "
//                 );
//             }
//         })
//         .catch(() => {
//             console.error("Failed to refresh token");
//         });
// }, 6000);
// })
// .catch(() => {
//     console.error("Authenticated Failed");
// });
// });

// function setKeyCloakConfig() {
//     return {
//         url: process.env.VUE_APP_ROOT_URL,
//         realm: process.env.VUE_APP_REALM,
//         clientId: process.env.VUE_APP_CLIENT_ID,
//         onLoad: process.env.VUE_APP_ON_LOAD,
//     };
// }
//
// function setKeyCloakInitOptions() {
//     return {
//         checkLoginIframe: true,
//         checkLoginIframeInterval: 25,
//         onLoad: "login-required"
//     };
// }
//
// async function initializeKeycloak() {
//     console.info("process.env ", process.env);
//     initOptions = setKeyCloakInitOptions();
//     const x = await getCustomURL();
//     return x;
// }
//
// function getCustomURL() {
//     const currentURL = window.location.origin + '/config/config.json';
//     return new Promise((resolve, reject) => {
//         CommonApi.getData(currentURL).then((res) => {
//             try {
//                 if (!res.realm)
//                     throw new Error('Something bad happened');
//                 const authUrl = res.authUrl;
//                 const realm = res.realm;
//                 const clientId = res.clientId;
//                 const checkLoginIframe = Boolean(res.checkLoginIframe);
//                 const checkLoginIframeInterval = +res.checkLoginIframeInterval;
//                 const initOptionsRes = {
//                     url: authUrl,
//                     realm: realm,
//                     clientId: clientId
//                 };
//                 initOptions.checkLoginIframe = checkLoginIframe;
//                 initOptions.checkLoginIframeInterval = checkLoginIframeInterval;
//                 resolve(Keycloak(initOptionsRes));
//             } catch (error) {
//                 if (process.env.VUE_APP_DEFAULT_CONFIG === "true") {
//                     const initOptionsRes = setKeyCloakConfig();
//                     resolve(Keycloak(initOptionsRes));
//                     return;
//                 }
//                 console.error("Error");
//                 reject(null);
//             }
//         }).catch(() => {
//             if (process.env.VUE_APP_DEFAULT_CONFIG === "true") {
//                 const initOptionsRes = setKeyCloakConfig();
//                 resolve(Keycloak(initOptionsRes));
//                 return;
//             }
//             console.error("Error");
//             reject(null);
//         });
//     });
// }
