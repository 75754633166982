
import { defineComponent } from "vue";
import ListBase from "@/classes/ListBase.vue";
import {EquipmentApi} from "@/api/equipment.api";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";

export default defineComponent({
  components: {DeleteDialog},
  mixins: [ListBase],
  mounted() {
    console.log(this.list)
  },
  methods: {
    openDeleteDialog(id, name) {
      this.selectedId = id;
      this.$refs.delete.openDialog("связи", `связь с показателем ${name}`);
    },
  }
});
