
import FilterBase from "@/classes/FilterBase.vue";
import {Field} from "@/types/fields";
import {defineComponent, PropType} from "vue";
import {BrandsFilterImp} from "@/components/classes/BrandsFilterImp";
import { CollectionAgentsImp } from "@/components/classes/CollectionAgentsImp";
import { CollectionAgentsApi } from "@/api/collection-agents.api";
import Accordion from "@/components/ui/Accordion.vue";

export default defineComponent({
  components: {
    Accordion
  },
  mixins: [FilterBase],
  props: {
    fields: Object as PropType<Field<unknown>[]>,
  },
  watch: {
    fields: {
      handler() {
        this.setFilterFields();
        this.applyFilter('filter');
      },
      deep: true
    }
  },
  methods: {
    changeFilter() {
      this.setQueryParams();
    },
    applyFilter() {
      this.filter.deleted = this.valuesForCheckboxes.deleted[0] === ''
          ? null : this.valuesForCheckboxes.deleted[0] === 'true';
      this.filterChange(new CollectionAgentsImp());
      this.$emit("filter", this.filter);
    },
    setQueryParams() {
      if (!this.$route.query.isRelated) {
        let queryParams: any = {};
        this.setQueryFromFilterParam(queryParams, ['name' , "id", "adapterType" , "description", "deleted"]);
        this.$router.push({query: queryParams});
      }
    },
    getFieldsVisible(name) {
      let result = this.fields.find(obj => {
        return obj.field === name && obj.value === true;
      });
      return result;
    },
    getQueryParams(){
      const query = this.$route.query;
      this.setFilterFromQueryParam(query, ['name' , "id", "adapterType" , "description", "deleted"]);
    },
    cleanFilter() {
      this.filter = new BrandsFilterImp();
      this.setFilterFields();
      this.filterChange(new BrandsFilterImp());
      this.$emit("filter", this.filter);
    },
    setFilterFields() {
      this.fields.filter((v) => v.value === false)
        .forEach(f => {
          if(f.field === 'name') {
            this.filter.name = '';
          }
        });
    },
    getAdapterType() {
      CollectionAgentsApi.getAdapterType()
        .then(res => {
          this.adapterTypes = res;
        })
    }
  },
  data: () => ({
    filter: new CollectionAgentsImp(),
    filterData: {},
    adapterTypes: [],
    valuesForCheckboxes: {
      archive: [
        {value: '', label: 'Неважно'},
        {value: 'true', label: 'Да'},
        {value: 'false', label: 'Нет'},
      ],
      deleted: ['']
    }
  }),
  mounted() {
    console.log('test')
    this.getAdapterType()
    this.getQueryParams();
    this.setFilterFields();
    this.applyFilter();
  },
})
