
import {defineComponent} from "vue";
import CharacteristicBase from "@/classes/CharacteristicBase.vue";
import {getMeasureData} from "@/consts/fields/measure.fields";
import {ClassifilesApi} from "@/api/classifires.api";
import {getMetricLimitData} from "@/consts/fields/metric-limit.fields";
import {ColorPicker} from "vue3-colorpicker";
import "vue3-colorpicker/style.css";



export default defineComponent({
  name: "MetricLimitCharacteristics",
  components:{ ColorPicker},
  mixins: [CharacteristicBase],
  data: () => ({
    data: getMetricLimitData(),
    validationFields: [
      {field: "name", validators: ["required"]},
    ],
  }),
  methods: {
  },
  mounted() {
    this.getCRUDSettings(99)
  }
})
