import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import {currentUser} from "@/consts/currentUser";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs"
import * as jwt from 'jsonwebtoken';
import { AuthService } from "@/api/auth";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: '/login'
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/login/Login.vue"),
  },
  {
    path: "/",
    name: "Menu",
    component: () => import("../views/menu/Menu.vue"),
    children: [
      {
        name: "Меню",
        path: "/menu",
        props: {
          title: "Система ",
        },
        component: () => import("../views/main-menu/MainMenu.vue"),
      },
      {
        name: "Справочники",
        path: "/directory/menu",
        props: {
          title: "Справочники",
        },
        component: () => import("../views/directory-menu/DirectoryMenu.vue"),
      },
      {
        path: "/incidents/",
        name: "Incidents",
        props: {
          title: "Заявки",
          relate: "с заявкой",
          dto: 'IncidentDto'
        },
        component: () => import("../views/incidents/Incidents.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'incidents').read
        },
      },
      {
        path: "/incidents/:id",
        name: "EditIncidents",
        props: {
          hasBackButton: true,
          dto: 'IncidentDto',
          title: "Заявка",
        },
        component: () => import("../views/incidents/IncidentView.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'incidents').read
        },
      },
      {
        path: "/incidents/add",
        name: "AddIncidents",
        props: {
          hasBackButton: true,
          dto: 'IncidentDto',
          title: "Добавление заявки",
        },
        component: () => import("../views/incidents/AddIncidents.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'incidents').create
        },
      },
      {
        name: "Команды поддержки",
        path: "/directory/support-teams",
        props: {
          title: "Команды поддержки",
          dto: 'SupportTeamListDto'
        },
        component: () => import("../views/support-teams/SupportTeams.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'support_teams').read
        },
      },
      {
        path: "/directory/support-teams/:id",
        name: "EditSupportTeam",
        props: {
          hasBackButton: true,
          title: "Команда поддержки",
        },
        component: () =>
            import( "../views/support-teams/SupportTeamsView.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'support_teams').read
        },
      },
      {
        path: "/directory/support-teams/add",
        name: "AddSupportTeam",
        props: {
          hasBackButton: true,
          title: "Добавление команды",
        },
        component: () => import("../views/support-teams/AddSupportTeam.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'support_teams').create
        },
      },
      {
        name: "Контрагенты",
        path: "/directory/counterparty",
        props: {
          title: "Контрагенты",
          dto: 'CounterpartyListDto',
          hasBackButton: true,
          relate: "с контрагентом",
        },
        component: () => import("../views/counterparty/Counterparty.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'contractors').read
        },
      },
      {
        path: "/directory/counterparty/:id",
        name: "EditCounterparty",
        props: {
          hasBackButton: true,
          title: "Контрагент",
        },
        component: () =>
            import( "../views/counterparty/CounterpartyView.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'contractors').read
        },
      },
      {
        path: "/directory/counterparty/add",
        name: "AddCounterparty",
        props: {
          hasBackButton: true,
          title: "Добавление контрагента",
        },
        component: () => import("../views/counterparty/AddCounterparty.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'contractors').create
        },
      },
      {
        name: "Служебные атрибуты",
        path: "/directory/custom-attributes",
        props: {
          title: "Служебные атрибуты",
          dto: 'CustomAttributeDto',
          hasBackButton: true,
          relate: "с cлужебным атрибутом",
        },
        component: () => import("../views/custom-attributes/CustomAttributes.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'service_attributes').read
        },
      },
      {
        path: "/directory/custom-attributes/:id",
        name: "EditCustomAttributes",
        props: {
          hasBackButton: true,
          title: "Служебные атрибуты",
        },
        component: () =>
            import( "../views/custom-attributes/CustomAttributesView.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'service_attributes').read
        },
      },
      {
        path: "/directory/custom-attributes/add",
        name: "AddCustomAttributes",
        props: {
          hasBackButton: true,
          title: "Добавление служебная информация",
        },
        component: () => import("../views/custom-attributes/AddCustomAttributes.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'service_attributes').create
        },
      },
      // {
      //   path: "/equipments",
      //   name: "Equipments",
      //   props: {
      //     title: "Объекты",
      //     relate: "с объектом",
      //     hasBackButton: false,
      //   },
      //   component: () => import( "../views/equipments/Equipments.vue"),
      //   beforeEnter: (to, from) => {
      //     return currentUser.value.userRoles.includes('nav_monitoring');
      //   },
      // },
      {
        path: "/monitoring/objects",
        name: "MonitoringObjects",
        props: {
          title: "Объекты",
          relate: "с объектом",
          hasBackButton: false,
          dto: 'MonitoringObjectListDto'
        },
        component: () => import( "../views/monitoring-objects/MonitoringObjects.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'monitoring').children.find(res => res.menuCode === 'object').read
        },
      },
      {
        path: "/monitoring/objects/:id",
        name: "EditMonitoringObject",
        props: {
          hasBackButton: true,
          title: "Объект",

        },
        component: () => import("../views/monitoring-objects/MonitoringObjectsView.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'monitoring').children.find(res => res.menuCode === 'object').read
        },
      },
      {
        path: "/monitoring/objects/add",
        name: "AddMonitoringObject",
        props: {
          hasBackButton: true,
          title: "Добавление объекта",
        },
        component: () => import("../views/monitoring-objects/MonitoringObjectAdd.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'monitoring').children.find(res => res.menuCode === 'object').create
        },
      },
      {
        path: "/services",
        name: "Services",
        props: {
          title: "Услуги",
          relate: "с услугой",
          dto: 'ServiceListDto'
        },
        component: () => import("../views/services/Services.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'monitoring').children.find(res => res.menuCode === 'service').read
        },
      },
      {
        path: "/services/add",
        name: "AddService",
        props: {
          hasBackButton: true,
          title: "Добавление услуги",
        },
        component: () => import( "../views/services/ServiceAdd.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'monitoring').children.find(res => res.menuCode === 'service').create
        },
      },
      {
        path: "/services/:id",
        name: "EditService",
        props: {
          hasBackButton: true,
          title: "Услуга",
        },
        component: () => import("../views/services/ServiceView.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'monitoring').children.find(res => res.menuCode === 'service').read
        },
      },
      // {
      //   path: "/equipments/add",
      //   name: "AddEquipment",
      //   props: {
      //     hasBackButton: true,
      //     title: "Добавление объекта",
      //   },
      //   component: () => import("../views/equipments/EquipmentAdd.vue"),
      //   beforeEnter: (to, from) => {
      //     return currentUser.value.userRoles.includes('nav_monitoring');
      //   },
      // },
      // {
      //   path: "/equipments/:id",
      //   name: "EditEquipment",
      //   props: {
      //     hasBackButton: true,
      //     title: "Объект",
      //   },
      //   component: () => import("../views/equipments/EquipmentView.vue"),
      //   beforeEnter: (to, from) => {
      //     return currentUser.value.userRoles.includes('nav_monitoring');
      //   },
      // },
      {
        path: "/directory/metrics-type",
        name: "Metrics",
        props: {
          title: "Типы метрик",
          relate: "типа метрик",
          dto: 'MetricListDtoWithMetricGroups'
        },
        component: () => import("../views/metrics/Metrics.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'types_of_metrics').read
        },
      },
      {
        path: "/directory/metrics-type/:id",
        name: "EditMetricsType",
        props: {
          hasBackButton: true,
          title: "Тип метрик",
        },
        component: () => import("../views/metrics/MetricsTypeView.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'types_of_metrics').read
        },
      },
      {
        path: "/directory/metrics-type/add",
        name: "AddMetricsType",
        props: {
          hasBackButton: true,
          title: "Добавление типа метрик",
        },
        component: () => import("../views/metrics/AddMetricsType.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'types_of_metrics').create
        },
      },

      {
        path: "/directory/metrics-indicators",
        name: "MetricsIndicators",
        props: {
          title: "Типы показателей",
          relate: "типа показателей",
          dto: 'IndicatorListDto',
          hasBackButton: false,
        },
        component: () => import("../views/metrics-indicators/MetricsIndicators.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'types_of_metrics').create
        },
      },
      {
        path: "/directory/metrics-indicators/:id",
        name: "EditMetricsIndicators",
        props: {
          hasBackButton: true,
          title: "Тип показателей",
        },
        component: () => import( "../views/metrics-indicators/MetricsIndicatorsView.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'types_of_metrics').create
        },
      },
      {
        path: "/directory/metrics-indicators/add",
        name: "AddMetricsIndicators",
        props: {
          hasBackButton: true,
          title: "Добавление типа показателей",
        },
        component: () => import( "../views/metrics-indicators/AddMetricsIndicators.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'types_of_metrics').create
        },
      },
      {
        path: "/directory/areas",
        name: "Monitoring",
        props: {
          title: "Местоположения",
          dto: 'AreaListDto'
        },
        component: () => import( "../views/monitoring/Monitoring.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'locations').read
        },
      },
      {
        path: "/directory/areas/:id",
        name: "EditArea",
        props: {
          hasBackButton: true,
          title: "Местоположение",
        },
        component: () => import( "../views/monitoring/AreaView.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'locations').read
        },
      },
      {
        path: "/directory/areas/add",
        name: "AddPlayground",
        props: {
          hasBackButton: true,
          title: "Добавление местоположения",
        },
        component: () => import("../views/monitoring/AddPlayground.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'locations').create
        },
      },

      {
        path: "/directory/metrics-groups",
        name: "MetricsGroups",
        props: {
          title: "Группы метрик",
          relate: "с группой метрик",
          dto: 'MetricGroupListDto'
        },
        component: () => import("../views/metric-groups/MetricsGroups.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'groups_of_metrics').read
        },
      },
      {
        path: "/directory/metrics-groups/:id",
        name: "EditMetricsGroups",
        props: {
          hasBackButton: true,
          title: "Группа метрик",
        },
        component: () => import("../views/metric-groups/MetricsGroupsView.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'groups_of_metrics').read
        },
      },
      {
        path: "/directory/metrics-groups/add",
        name: "AddMetricsGroups",
        props: {
          hasBackButton: true,
          title: "Добавление группы метрик",
        },
        component: () => import("../views/metric-groups/AddMetricsGroups.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'groups_of_metrics').create
        },
      },

      {
        path: "/directory/units",
        name: "Units",
        props: {
          title: "Единицы измерения",
          dto: 'UnitDto'
        },
        component: () => import("../views/units/Units.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'units_of_measurement').read
        },
      },
      {
        path: "/directory/units/:id",
        name: "EditUnits",
        props: {
          hasBackButton: true,
          title: "Единица измерения",
        },
        component: () => import("../views/units/UnitView.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'units_of_measurement').read
        },
      },
      {
        path: "/directory/units/add",
        name: "AddUnits",
        props: {
          hasBackButton: true,
          title: "Добавление единицы измерения",
        },
        component: () => import("../views/units/AddUnit.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'units_of_measurement').create
        },
      },
      {
        path: "/directory/contracts",
        name: "Contracts",
        props: {
          title: "Договоры",
          relate: "с договором",
          dto: 'ContractListDto'
        },
        component: () => import("../views/contracts/Contracts.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'contracts').read
        },
      },
      {
        path: "/directory/contracts/:id",
        name: "EditContracts",
        props: {
          hasBackButton: true,
          title: "Договор",
        },
        component: () => import("../views/contracts/ContractsView.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'contracts').read
        },
      },
      {
        path: "/directory/contracts/add",
        name: "AddContracts",
        props: {
          hasBackButton: true,
          title: "Добавление договора",
        },
        component: () => import("../views/contracts/AddContracts.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'contracts').create
        },
      },
      {
        path: "/directory/departments",
        name: "Departments",
        props: {
          title: "Подразделения",
          relate: "с подразделением",
          dto: 'DepartmentListDto'
        },
        component: () => import("../views/departments/Departments.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'divisions').read
        },
      },
      {
        path: "/directory/departments/:id",
        name: "EditDepartments",
        props: {
          hasBackButton: true,
          title: "Подразделение",
        },
        component: () => import("../views/departments/DepartmentsView.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'divisions').read
        },
      },
      {
        path: "/directory/departments/add",
        name: "AddDepartments",
        props: {
          hasBackButton: true,
          title: "Добавление подразделения",
        },
        component: () => import("../views/departments/AddDepartments.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'divisions').create
        },
      },
      {
        path: "/directory/equipment-types",
        name: "EquipmentTypes",
        props: {
          title: "Типы объектов",
          relate: "с типом объектов",
          dto: 'MonitoringObjectTypeListDto'
        },
        component: () => import("../views/equipment-types/EquipmentTypes.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'types_of_objects').read
        },
      },
      {
        path: "/directory/equipment-types/:id",
        name: "EditEquipmentTypes",
        props: {
          hasBackButton: true,
          title: "Тип объекта",
        },
        component: () => import("../views/equipment-types/EquipmentTypesView.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'types_of_objects').read
        },
      },
      {
        path: "/directory/equipment-types/add",
        name: "AddEquipmentTypes",
        props: {
          hasBackButton: true,
          title: "Добавление типа объектов",
        },
        component: () => import("../views/equipment-types/AddEquipmentTypes.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'types_of_objects').create
        },
      },
      {
        path: "/directory/area-types",
        name: "AreaTypes",
        props: {
          title: "Типы местоположений",
          dto: 'AreaTypeDto'
        },
        component: () => import("../views/area-types/AreaTypes.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'types_of_locations').read
        },
      },
      {
        path: "/directory/area-types/:id",
        name: "EditAreaTypes",
        props: {
          hasBackButton: true,
          title: "Тип местоположения",
        },
        component: () => import("../views/area-types/AreaTypesView.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'types_of_locations').read
        },
      },
      {
        path: "/directory/area-types/add",
        name: "AddAreaTypes",
        props: {
          hasBackButton: true,
          title: "Добавление типа местоположения",
        },
        component: () => import("../views/area-types/AddAreaTypes.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'types_of_locations').create
        },
      },

      {
        path: "/directory/channel-types",
        name: "ChannelTypes",
        props: {
          title: "Типы услуги",
          dto: 'ChannelTypeDto'
        },
        component: () => import("../views/channel-types/ChannelTypes.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'types_of_services').read
        },
      },
      {
        path: "/directory/channel-types/:id",
        name: "EditChannelTypes",
        props: {
          hasBackButton: true,
          title: "Тип услуги",
        },
        component: () => import("../views/channel-types/ChannelTypesView.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'types_of_services').read
        },
      },
      {
        path: "/directory/channel-types/add",
        name: "AddChannelTypes",
        props: {
          hasBackButton: true,
          title: "Добавление типа услуги",
        },
        component: () => import("../views/channel-types/AddChannelTypes.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'types_of_services').create
        },
      },
      {
        path: "/directory/incident-type",
        name: "IncidentType",
        props: {
          title: "Типы заявок",
        },
        component: () => import("../views/incident-type/IncidentType.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'types_of_applications').read
        },
      },
      {
        path: "/directory/incident-type/:id",
        name: "EditIncidentType",
        props: {
          hasBackButton: true,
          title: "Тип заявки",
        },
        component: () => import("../views/incident-type/IncidentTypeView.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'types_of_applications').read
        },
      },
      {
        path: "/directory/incident-types/add",
        name: "AddIncidentType",
        props: {
          hasBackButton: true,
          title: "Добавление типа заявки",
        },
        component: () => import("../views/incident-type/IncidentTypeAdd.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'types_of_applications').create
        },
      },
      {
        path: "/directory/responsibility",
        name: "AreasResponsibility",
        props: {
          title: "Зоны ответственности",
          dto: 'ResponseZoneDto'
        },
        component: () => import("../views/areas-responsibility/AreasResponsibility.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'areas_of_responsibility').read
        },
      },
      {
        path: "/directory/responsibility/:id",
        name: "EditAreasResponsibility",
        props: {
          hasBackButton: true,
          title: "Зона ответственности",
        },
        component: () => import("../views/areas-responsibility/AreasResponsibilityView.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'areas_of_responsibility').read
        },
      },
      {
        path: "/directory/responsibility/add",
        name: "AddAreasResponsibility",
        props: {
          hasBackButton: true,
          title: "Добавление зоны ответственности",
        },
        component: () => import("../views/areas-responsibility/AddAreasResponsibility.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'areas_of_responsibility').create
        },
      },

      // {
      //   path: "/directory/operators",
      //   name: "Operators",
      //   props: {
      //     title: "Операторы последней мили",
      //   },
      //   component: () =>
      //     import("../views/operators/Operators.vue"),
      //   beforeEnter: (to, from) => {
      //     return currentUser.value.userRoles.includes('nav_monitoring');
      //   },
      // },
      // {
      //   path: "/directory/operators/:id",
      //   name: "EditOperators",
      //   props: {
      //     hasBackButton: true,
      //     title: "Оператор последней мили",
      //   },
      //   component: () =>
      //     import("../views/operators/OperatorsView.vue"
      //     ),
      //   beforeEnter: (to, from) => {
      //     return currentUser.value.userRoles.includes('nav_monitoring');
      //   },
      // },
      // {
      //   path: "/directory/operators/add",
      //   name: "AddOperators",
      //   props: {
      //     hasBackButton: true,
      //     title: "Добавление оператора последней мили",
      //   },
      //   component: () => import("../views/operators/AddOperators.vue"),
      //   beforeEnter: (to, from) => {
      //     return currentUser.value.userRoles.includes('nav_monitoring');
      //   },
      // },
      {
        path: "/directory/operation-queue",
        name: "OperationQueue",
        props: {
          title: "Этапы внедрения",
          dto: 'AreaStageDto'
        },
        component: () => import("../views/operation-queue/OperationQueues.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'stages_of_implementation').read
        },
      },
      {
        path: "/directory/operation-queue/:id",
        name: "EditOperationQueue",
        props: {
          hasBackButton: true,
          title: "Этап внедрения",
        },
        component: () => import("../views/operation-queue/OperationQueueView.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'stages_of_implementation').read
        },
      },
      {
        path: "/directory/operation-queue/add",
        name: "AddOperationQueue",
        props: {
          hasBackButton: true,
          title: "Добавление этапа внедрения",
        },
        component: () => import("../views/operation-queue/AddOperationQueue.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'stages_of_implementation').create
        },
      },

      {
        path: "/security/executor-users",
        name: "ExecutorUsers",
        props: {
          title: "Пользователи",
          relate: "с пользователями",
          dto: 'UserListDto'
        },
        component: () => import( "../views/executor-users/ExecutorUsers.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'security').children.find(res => res.menuCode === 'users').read
        },
      },
      {
        path: "/security/executor-users/:id",
        name: "EditExecutorUsers",
        props: {
          hasBackButton: true,
          title: "Пользователь",
        },
        component: () => import( "../views/executor-users/ExecutorUsersView.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'security').children.find(res => res.menuCode === 'users').read
        },
      },
      {
        path: "/security/executor-users/add",
        name: "AddExecutorUsers",
        props: {
          hasBackButton: true,
          title: "Добавление пользователя",
        },
        component: () => import("../views/executor-users/AddExecutorUsers.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'security').children.find(res => res.menuCode === 'users').create
        },
      },
      {
        path: "/security/changelog",
        name: "Changelog",
        props: {
          title: "Журнал",
        },
        component: () => import( "../views/changelog/Changelog.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'security').children.find(res => res.menuCode === 'journal').read
        },
      },
      {
        path: "/security/authlog",
        name: "Authlog",
        props: {
          title: "Журнал авторизации",
        },
        component: () => import( "../views/authlog/Authlog.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'security').children.find(res => res.menuCode === 'authorization_log').read
        },
      },
      {
        path: "/settings/file-manager",
        name: "FileManager",
        props: {
          title: "Управление файлами",
          dto: 'FileManagerFilterDto'
        },
        component: () => import( "../views/file-manager/FileManager.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'settings').children.find(res => res.menuCode === 'file_management').read
        },
      },
      {
        path: "/security/active-sessions-log",
        name: "ActiveSessionsLog",
        props: {
          title: "Журнал сессий",
        },
        component: () => import( "@/views/active-session-log/ActiveSessionLog.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'security').children.find(res => res.menuCode === 'session_log').read
        },
      },
      {
        path: "/settings/representations",
        name: "Representations",
        props: {
          title: "Представления",
          dto: 'ViewSettingsDto'
        },
        component: () => import("../views/representations/Representations.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'settings').children.find(res => res.menuCode === 'performances').read
        },
      },
      {
        path: "/settings/view/:id",
        name: "EditView",
        props: {
          hasBackButton: true,
          title: "Представление",
        },
        component: () => import( "../views/view-settings/ViewSettingsView.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'settings').children.find(res => res.menuCode === 'performances').read
        },
      },
      {
        path: "/settings/view",
        name: "EditProcesses",
        props: {
          hasBackButton: true,
          title: "Представление",
        },
        component: () => import( "../components/processes/EditProcesses.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'settings').children.find(res => res.menuCode === 'performances').read
        },
      },
      {
        path: "/settings/processes/edit/:id",
        name: "EditProcesses",
        props: {
          hasBackButton: true,
          title: "Редактирование процессов",
        },
        component: () => import( "../components/processes/EditProcesses.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'settings').children.find(res => res.menuCode === 'processes').read
        },
      },
      {
        path: "/settings/processes",
        name: "Processes",
        props: {
          title: "Процессы",
          dto: 'EntityProcessDefinitionDto'
        },
        component: () => import("../views/processes/Processes.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'settings').children.find(res => res.menuCode === 'processes').read
        },
      },
      {
        path: "/settings/processes/:id",
        name: "EditProcess",
        props: {
          hasBackButton: true,
          title: "Процесс",
        },
        component: () => import("../views/processes/ProcessView.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'settings').children.find(res => res.menuCode === 'processes').read
        },
      },
      {
        path: "/settings/processes/add",
        name: "ProcessAdd",
        props: {
          hasBackButton: true,
          title: "Добавление процесса",
        },
        component: () => import("../views/processes/AddProcess.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'settings').children.find(res => res.menuCode === 'processes').create
        },
      },
      {
        path: "/settings/monitoring-templates",
        name: "MonitoringTemplates",
        props: {
          title: "Шаблоны мониторинга",
        },
        component: () => import("../views/monitoring-templates/MonitoringTemplates.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'settings').children.find(res => res.menuCode === 'monitoring_templates').read
        },
      },
      {
        path: "/settings/monitoring-templates/add",
        name: "AddMonitoringTemplate",
        props: {
          hasBackButton: true,
          title: "Добавление шаблонов мониторинга",
        },
        component: () => import("../views/monitoring-templates/AddMonitoringTemplate.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'settings').children.find(res => res.menuCode === 'monitoring_templates').create
        },
      },
      {
        path: "/settings/monitoring-templates/:id",
        name: "EditProcess",
        props: {
          hasBackButton: true,
          title: "Шаблон мониторинга",
        },
        component: () => import("../views/monitoring-templates/MonitoringTemplateView.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'settings').children.find(res => res.menuCode === 'monitoring_templates').read
        },
      },
      // {
      //   path: "/settings/decision-definitions",
      //   name: "DecisionDefinitions",
      //   props: {
      //     title: "Правила действий",
      //   },
      //   component: () => import("../views/decision-definitions/DecisionDefinitions.vue"),
      //   beforeEnter: (to, from) => {
      //     return currentUser.value.userRoles.includes('nav_settings');
      //   },
      // },
      // {
      //   path: "/settings/decision-definitions/edit/:id",
      //   name: "EditDecisionDefinition",
      //   props: {
      //     hasBackButton: true,
      //     title: "Редактирование правила действий",
      //   },
      //   component: () => import( "../views/decision-definitions/DecisionDefinitionsView.vue"),
      //   beforeEnter: (to, from) => {
      //     return currentUser.value.userRoles.includes('nav_settings');
      //   },
      // },
      // {
      //   path: "/settings/decision-definitions",
      //   name: "DecisionDefinitions",
      //   props: {
      //     title: "Правила действий",
      //   },
      //   component: () => import("../views/decision-definitions/DecisionDefinitions.vue"),
      //   beforeEnter: (to, from) => {
      //     return currentUser.value.userRoles.includes('nav_settings');
      //   },
      // },
      {
        path: "/settings/decision-definitions",
        name: "EditDecisionDefinition",
        props: {
          hasBackButton: true,
          title: "Редактирование правила действий",
        },
        component: () => import( "../components/decision-definitions/EditDecisionDefinitions.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'settings').children.find(res => res.menuCode === 'rules_of_action').read
        },
      },
      {
        path: "/settings/imports/edit/:id",
        name: "EditImports",
        props: {
          hasBackButton: true,
          title: "Редактирование импорта данных",
        },
        component: () => import( "../components/processes/EditProcesses.vue"),
      },
      {
        path: "/settings/imports",
        name: "Imports",
        props: {
          title: "Импорт данных",
        },
        component: () => import("../views/imports/Imports.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'settings').children.find(res => res.menuCode === 'data_import').read
        },
      },
      {
        path: "/settings/imports/add",
        name: "AddImport",
        props: {
          hasBackButton: true,
          title: "Добавление импорта данных",
        },
        component: () => import("../views/imports/ImportAdd.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'settings').children.find(res => res.menuCode === 'data_import').create
        },
      },
      {
        path: "/settings/process-templates",
        name: "Representations.vue",
        props: {
          title: "Конструктор процессов",
        },
        component: () => import("../views/process-templates/ProcessTemplates.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'settings').children.find(res => res.menuCode === 'process_designer').read
        },
      },
      {
        path: "/settings/notification",
        name: "MailNotification",
        props: {
          title: "Оповещения",
        },
        component: () => import("../views/mail-notification/MailNotification.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'settings').children.find(res => res.menuCode === 'alerts').read
        },
      },
      {
        path: "/settings/notification/:id",
        name: "EditMailNotification",
        props: {
          hasBackButton: true,
          title: "Оповещение",
        },
        component: () => import( "../views/mail-notification/MailNotificationView.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'settings').children.find(res => res.menuCode === 'alerts').read
        },
      },
      {
        path: "/settings/notification/add",
        name: "AddMailNotification",
        props: {
          hasBackButton: true,
          title: "Добавление оповещения",
        },
        component: () => import("../views/mail-notification/AddMailNotification.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'settings').children.find(res => res.menuCode === 'alerts').create
        },
      },
      {
        path: "/settings/mail",
        name: "Mail",
        props: {
          title: "Почта",
        },
        component: () => import("../views/mail/Mail.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'settings').children.find(res => res.menuCode === 'mail').read
        },
      },
      {
        path: "/settings/mail/:id",
        name: "EditMail",
        props: {
          hasBackButton: true,
          title: "Почта",
        },
        component: () => import( "../views/mail/MailView.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'settings').children.find(res => res.menuCode === 'mail').read
        },
      },
      {
        path: "/settings/mail/add",
        name: "AddMail",
        props: {
          hasBackButton: true,
          title: "Добавление почты",
        },
        component: () => import("../views/mail/AddMail.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'settings').children.find(res => res.menuCode === 'alerts').create
        },
      },
      {
        path: "/settings/system",
        name: "System",
        props: {
          title: "Система",
          dto: 'SystemLogoListDto'
        },
        component: () => import("../views/system/System.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'settings').children.find(res => res.menuCode === 'system').read
        },
      },
      {
        path: "/settings/system/:id",
        name: "EditSystem",
        props: {
          hasBackButton: true,
          title: "Система",
        },
        component: () => import( "../views/system/SystemView.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'settings').children.find(res => res.menuCode === 'system').read
        },
      },
      {
        path: "/settings/system/add",
        name: "AddSystem",
        props: {
          hasBackButton: true,
          title: "Добавление системы",
        },
        component: () => import( "../views/system/AddSystem.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'settings').children.find(res => res.menuCode === 'system').create
        },
      },
      {
        path: "/settings/process-templates/add",
        name: "AddProcessTemplate",
        props: {
          hasBackButton: true,
          title: "Добавление шаблона процесса",
        },
        component: () => import("../components/processes/AddProcessTemplate.vue"),
      },
      {
        path: "/settings/collection-agents",
        name: "CollectionAgents",
        props: {
          hasBackButton: true,
          title: "Агенты сбора данных",
          dto: 'CollectionAgentDto'
        },
        component: () => import("../views/collection-agents/CollectionAgents.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'settings').children.find(res => res.menuCode === 'data_collection_agents').read
        },
      },
      {
        path: "/settings/collection-agents/:id",
        name: "CollectionAgentsView",
        props: {
          hasBackButton: true,
          title: "Агент сбора данных",
        },
        component: () => import("../views/collection-agents/CollectionAgentView.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'settings').children.find(res => res.menuCode === 'data_collection_agents').read
        },
      },
      {
        path: "/settings/collection-agents/add",
        name: "AddCollectionAgent",
        props: {
          hasBackButton: true,
          title: "Добавление агента сбора данных",
        },
        component: () => import("../views/collection-agents/AddCollectionAgent.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'settings').children.find(res => res.menuCode === 'data_collection_agents').create
        },
      },
      {
        path: "/dashboards",
        name: "Dashboards",
        props: {
          title: "Аналитика",
        },
        component: () => import("../views/dashboards/Dashboards.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'analytic').read
        },
      },
      {
        path: "/security/groups",
        name: "Groups",
        props: {
          title: "Группы",
          relate: "с группами",
          dto: 'GroupDto'
        },
        component: () => import("../views/groups/Groups.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'security').children.find(res => res.menuCode === 'groups').read
        },
      },
      {
        path: "/security/role-objects",
        name: "RoleObjects",
        props: {
          title: "Объекты",
          relate: "с объектами ролей",
          dto: 'GroupDto'
        },
        component: () => import("../views/role-objects/RoleObjects.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'security').children.find(res => res.menuCode === 'roles').read
        },
      },
      {
        path: "/security/groups/add",
        name: "AddGroup",
        props: {
          hasBackButton: true,
          title: "Добавление группы",
        },
        component: () => import("../views/groups/GroupAdd.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'security').children.find(res => res.menuCode === 'groups').create
        },
      },
      {
        path: "/security/groups/:id",
        name: "EditGroup",
        props: {
          hasBackButton: true,
          title: "Группа",
        },
        component: () => import("../views/groups/GroupView.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'security').children.find(res => res.menuCode === 'groups').read
        },
      },
      {
        path: "/directory/models",
        name: "Models",
        props: {
          title: "Модели",
          dto: 'ModelListDto'
        },
        component: () => import("../views/models/Models.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'model').read
        },
      },
      {
        path: "/directory/models/add",
        name: "AddModel",
        props: {
          hasBackButton: true,
          title: "Добавление модели",
        },
        component: () => import("../views/models/ModelAdd.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'model').create
        },
      },
      {
        path: "/directory/models/:id",
        name: "EditModel",
        props: {
          hasBackButton: true,
          title: "Модель",
        },
        component: () => import("../views/models/ModelView.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'model').read
        },
      },
      {
        path: "/directory/calculated-status",
        name: "CalculatedStatus",
        props: {
          title: "Правила расчета статусов",
          relate: "правилами расчета статусов",
          dto: 'CalculateStatusRuleDto'
        },
        component: () => import("../views/calculated-status-rule/CalculatedStatusRule.vue"),
        // component: () => import("../views/metrics/Metrics.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'calculated_status_rule').read
        },
      },
      {
        path: "/directory/calculated-status/:id",
        name: "EditCalculatedStatus",
        props: {
          hasBackButton: true,
          title: "Правило расчета статуса",
        },
        component: () => import("../views/calculated-status-rule/CalculatedStatusRuleView.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'calculated_status_rule').read
        },
      },
      {
        path: "/directory/calculated-status/add",
        name: "AddMetricsType",
        props: {
          hasBackButton: true,
          title: "Добавление правила расчета статусов",
        },
        component: () => import("../views/calculated-status-rule/AddCalculatedStatusRule.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'calculated_status_rule').create
        },
      },
      {
        path: "/directory/brands",
        name: "Brands",
        props: {
          title: "Производители",
          dto: 'BrandDto'
        },
        component: () => import("../views/brands/Brands.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'manufacturers').read
        },
      },
      {
        path: "/directory/brands/add",
        name: "AddBrand",
        props: {
          hasBackButton: true,
          title: "Добавление производителя",
        },
        component: () => import("../views/brands/BrandAdd.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'manufacturers').create
        },
      },
      {
        path: "/directory/brands/:id",
        name: "EditBrand",
        props: {
          hasBackButton: true,
          title: "Производитель",
        },
        component: () => import("../views/brands/BrandView.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'manufacturers').read
        },
      },
      {
        path: "/directory/measure",
        name: "Measure",
        props: {
          title: "Единицы измерения",
          dto: 'MeasureDto'
        },
        component: () => import("../views/measure/Measure.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'units_of_measurement').read
        }
      },
      {
        path: "/directory/measure/add",
        name: "AddMeasure",
        props: {
          hasBackButton: true,
          title: "Добавление единиц измерения",
        },
        component: () => import("../views/measure/AddMeasure.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'units_of_measurement').create
        },
      },
      {
        path: "/directory/measure/:id",
        name: "EditMeasure",
        props: {
          hasBackButton: true,
          title: "Единица измерения",
        },
        component: () => import("../views/measure/MeasureView.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'units_of_measurement').read
        },
      },
      {
        path: "/directory/metric-tag",
        name: "MetricTag",
        props: {
          title: "Категории метрик",
          dto: "MetricTagDto"
        },
        component: () => import("../views/metric-tag/MetricTag.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'categories_of_metrics').read
        }
      },
      {
        path: "/directory/metric-tag/add",
        name: "AddMetricTag",
        props: {
          hasBackButton: true,
          title: "Добавление категории метрик",
        },
        component: () => import("../views/metric-tag/AddMetricTag.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'categories_of_metrics').create
        },
      },
      {
        path: "/directory/metric-tag/:id",
        name: "EditMetricTag",
        props: {
          hasBackButton: true,
          title: "Категория метрик",
        },
        component: () => import("../views/metric-tag/MetricTagView.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'categories_of_metrics').read
        },
      },
      {
        path: "/directory/metric-limits",
        name: "MetricLimits",
        props: {
          title: "Лимит метрики",
        },
        component: () => import("../views/metric-limits/MetricLimits.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'metric_limit').read
        }
      },
      {
        path: "/directory/metric-limits/add",
        name: "AddMetricLimit",
        props: {
          hasBackButton: true,
          title: "Добавление лимита метрик",
        },
        component: () => import("../views/metric-limits/AddMetricLimit.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'metric_limit').create
        },
      },
      {
        path: "/directory/metric-limits/:id",
        name: "EditMetricLimit",
        props: {
          hasBackButton: true,
          title: "Лимит метрик",
        },
        component: () => import("../views/metric-limits/MetricLimitView.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'metric_limit').read
        },
      },
      {
        path: "/directory/monitoring-object-statuses",
        name: "MonitoringObjectStatuses",
        props: {
          title: "Статусы объектов мониторинга",
        },
        component: () => import("../views/monitoring-object-statuses/MonitoringObjectStatuses.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'monitoring_object_statuses').read
        }
      },
      {
        path: "/directory/monitoring-object-statuses/add",
        name: "AddMonitoringObjectStatuses",
        props: {
          hasBackButton: true,
          title: "Добавление статуса объекта мониторинга",
        },
        component: () => import("../views/monitoring-object-statuses/AddMonitoringObjectStatuses.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'monitoring_object_statuses').create
        },
      },
      {
        path: "/directory/monitoring-object-statuses/:id",
        name: "EditMonitoringObjectStatuses",
        props: {
          hasBackButton: true,
          title: "Статус объектов мониторинга",
        },
        component: () => import("../views/monitoring-object-statuses/MonitoringObjectStatusesView.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'monitoring_object_statuses').read
        },
      },
      {
        path: "/security/roles",
        name: "Roles",
        props: {
          title: "Роли",
          relate: "с ролями",
          dto: 'UserRoleDto'
        },
        component: () => import("../views/roles/Roles.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'security').children.find(res => res.menuCode === 'roles').read
        },
      },
      {
        path: "/security/roles/add",
        name: "AddRole",
        props: {
          hasBackButton: true,
          title: "Добавление роли",
        },
        component: () => import("../views/roles/RoleAdd.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'security').children.find(res => res.menuCode === 'roles').read
        },
      },
      {
        path: "/security/roles/:id",
        name: "EditRole",
        props: {
          hasBackButton: true,
          title: "Роль",
        },
        component: () => import("../views/roles/RoleView.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'security').children.find(res => res.menuCode === 'roles').read
        },
      },
      {
        path: "/reports",
        name: "Reports",
        props: {
          hasBackButton: false,
          title: "Отчеты",
          dto: 'ReportsExecDto'
        },
        component: () => import("../views/reports/Reports.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'reports').read
        },
      },
      {
        path: "/info/",
        name: "Info",
        props: {
          hasBackButton: true,
          title: "О системе",
        },
        component: () => import("../views/info-page/Info.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'about_system').read
        },
      },
      {
        name: "Шаблоны модели здоровья",
        path: "/directory/health-model-rule",
        props: {
          title: "Шаблоны модели здоровья",
        },
        component: () => import("../views/health-model-rule/HealthModelRule.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'template_for_health_models').read
        },
      },
      {
        path: "/directory/health-model-rule/:id",
        name: "EditHealthModelRule",
        props: {
          hasBackButton: true,
          title: "Шаблон модели здоровья"
        },
        component: () =>
            import( "../views/health-model-rule/HealthModelRuleView.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'template_for_health_models').read
        },
      },
      {
        path: "/directory/health-model-rules/:id",
        name: "EditHealthModelRules",
        props: {
          hasBackButton: true,
          title: "Правило модели здоровья"
        },
        component: () =>
            import( "../views/health-model-rule/HealthModelRulesView.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'template_for_health_models').read
        },
      },
      {
        path: "/directory/health-model-rule/add",
        name: "AddHealthModelRule",
        props: {
          hasBackButton: true,
          title: "Добавление шаблона модели здоровья",
        },
        component: () => import("../views/health-model-rule/HealthModelRuleAdd.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'template_for_health_models').create
        },
      },
      {
        path: "/directory/health-model-rules/add",
        name: "AddHealthModelRules",
        props: {
          hasBackButton: true,
          title: "Добавление правила модели здоровья",
        },
        component: () => import("../views/health-model-rule/HealthModelRulesAdd.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'template_for_health_models').create
        },
      },
      {
        name: "Правила расчета",
        path: "/directory/calculation-rules",
        props: {
          title: "Правила расчета",
          dto: 'CalculationRuleDto'
        },
        component: () => import("../views/calculation-rules/CalculationRules.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'template_for_health_models').read
        },
      },
      {
        path: "/directory/calculation-rules/:id",
        name: "EditCalculationRule",
        props: {
          hasBackButton: true,
          title: "Правило расчета"
        },
        component: () =>
            import( "../views/calculation-rules/CalculationRulesView.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'template_for_health_models').read
        },
      },
      {
        path: "/directory/calculation-rules/add",
        name: "AddCalculationRule",
        props: {
          hasBackButton: true,
          title: "Добавление правила расчета",
        },
        component: () => import("../views/calculation-rules/AddCalculationRule.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'template_for_health_models').create
        },
      },
      {
        path: "/directory/notice-template",
        name: "template",
        props: {
          title: "Шаблоны уведомлений",
          dto: "NoticeTemplateListDto"
        },
        component: () => import("../views/notice-template/NoticeTemplate.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'notification_template').read
        },
      },
      {
        path: "/directory/notice-template/:id",
        name: "EditNoticeTemplate",
        props: {
          hasBackButton: true,
          title: "Шаблон уведомлений"
        },
        component: () =>
            import( "../views/notice-template/NoticeTemplateView.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'notification_template').read
        },
      },
      {
        path: "/directory/notice-template/add",
        name: "AddNoticeTemplate",
        props: {
          hasBackButton: true,
          title: "Добавление шаблона уведомлений",
        },
        component: () => import("../views/notice-template/AddNoticeTemplate.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'notification_template').create
        },
      },
      {
        path: "/directory/urgency",
        props: {
          title: "Срочность",
          hasBackButton: true,
          dto: 'UrgencyDto'
        },
        component: () => import("../views/urgency/Urgency.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'urgency').read
        },
      },
      {
        path: "/directory/urgency/:id",
        name: "EditUrgency",
        props: {
          hasBackButton: true,
          title: "Срочность",
        },
        component: () => import("../views/urgency/UrgencyView.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'urgency').read
        },
      },
      {
        path: "/directory/urgency/add",
        name: "AddUrgency",
        props: {
          hasBackButton: true,
          title: "Добавление строчности",
        },
        component: () => import("../views/urgency/AddUrgency.vue"),
        beforeEnter: (to, from) => {
          return currentUser.value.access.items.find((res) => res.menuCode === 'dictionary').children.find(res => res.menuCode === 'urgency').create
        },
      },
    ]
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const subject$ = new Subject();

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('auth_token')

  const decodedToken = jwt.decode(token, { complete: true });
  const currentTimestamp = Math.floor(Date.now() / 1000);

  if (!token) {
    const decodedToken = jwt.decode(token, { complete: true });
    const currentTimestamp = Math.floor(Date.now() / 1000);
    if (decodedToken && decodedToken.payload && decodedToken.payload.exp <= currentTimestamp) {
      router.replace({
        path: "/login",
        query: {
          redirectUrl: window.location.pathname !== 'login' ? window.location.pathname + window.location.search : null
        }})
        .then(() => next());
      return;
    }
  } else {
    if (decodedToken && decodedToken.payload && decodedToken.payload.exp <= currentTimestamp) {
      router.replace({
        path: "/login",
        query: {
          redirectUrl: window.location.pathname !== 'login' ? window.location.pathname + window.location.search : null
        }})
        .then(() => next());
      return;
    }
  }

  console.log(currentUser.value)
  if (to.path === '/login') {
    console.log(to);
    console.log(next);
    next();
    return;
  }

  if (currentUser.value === null) {
    currentUser.pipe(takeUntil(subject$))
        .subscribe(value => {
          if (value != null) {
            next();
          }
        });
  } else
    next();
})

router.afterEach((to, from) => {
  subject$.next();
  subject$.complete();
})

export default router;
