
import {defineComponent} from "vue";
import CharacteristicBase from "@/classes/CharacteristicBase.vue";
import {getMetricTagData} from "@/consts/fields/metric-tag.fields";

export default defineComponent({
  name: "MetricTagCharacteristics",
  mixins: [CharacteristicBase],
  data: () => ({
    data: getMetricTagData()
  }),
  mounted() {
    this.getCRUDSettings(98)
  }
})
