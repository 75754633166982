
import { Util } from "@/components/utils/Util";
import { mixins, Options, Vue } from "vue-class-component";
import Accordion from "@/components/ui/Accordion.vue";
import FilterBase from "@/classes/FilterBase.vue";
import Super from "@/classes/FilterBase.vue";
import locale from "ant-design-vue/es/time-picker/locale/ru_RU";
import { Field } from "@/types/fields";
import { defineComponent, PropType } from "vue";
import { MetricFilterImp } from "@/components/classes/MetricFilterImp";
import { MetricGroupApi } from "@/api/metric-group.api";

export default defineComponent({
  name: "MetricsFilter",
  components: {
    Accordion,
  },
  mixins: [FilterBase],
  props: {
    fields: Object as PropType<Field<unknown>[]>,
  },
  watch: {
    fields: {
      handler() {
        this.setFilterFields();
        this.applyFilter("filter");
      },
      deep: true,
    },
  },
  methods: {
    changeFilter() {
      this.setQueryParams();
    },
    handleVisibleChange(e1) {
      console.log(e1);
    },
    onCheckLastValue(array, name) {
      Util.onCheckLastValue(array, name);
    },
    onCheckManyValues(array, name) {
      Util.onCheckManyValues(array, name);
    },
    getMetricGroups(metricGroupName?) {
      const arrChecked = [];
      if (this.valuesForCheckboxes.metricGroupsArr.length > 0) {
        this.valuesForCheckboxes.metricGroupsArr.forEach(id => {
          const checked = this.valuesForCheckboxes.metricGroups.filter(mg => mg.id === id);
          if (checked.length > 0) {
            arrChecked.push(checked[0]);
          }
        });
      }
      const name = metricGroupName || this.metricGroup;
      MetricGroupApi.applyFilter(
          {
            name: name,
          },
          15,
          0
      ).then((res) => {
        this.valuesForCheckboxes.metricGroups = Util.transformToCheckboxes(res.data);
        if (arrChecked.length > 0) {
          arrChecked.forEach(c => {
            if (!this.valuesForCheckboxes.metricGroups.some(mg => mg.id === c.id)) {
              this.valuesForCheckboxes.metricGroups.push(c);
            }
          });
        }
      });
    },
    applyFilter() {
      this.filter.activity =
        this.valuesForCheckboxes.activitiesArr[0] === ""
          ? ""
          : this.valuesForCheckboxes.activitiesArr[0] === "true";
      this.filter.testKpd =
        this.valuesForCheckboxes.testKpdArr[0] === ""
          ? ""
          : this.valuesForCheckboxes.testKpdArr[0] === "true";
      this.filter.metricGroupsIds =
        this.valuesForCheckboxes.metricGroupsArr.length === 1 &&
        this.valuesForCheckboxes.metricGroupsArr[0] === ""
          ? []
          : this.valuesForCheckboxes.metricGroupsArr;
      this.filterChange(new MetricFilterImp());
      this.$emit("filter", this.filter);
    },
    setQueryParams() {
      let queryParams: any = {};
      this.setQueryFromFilterParam(queryParams, ["id", "name", "key" , "monitoringSystemName"]);
      this.setQueryParamsArrDefault(queryParams, []);
      this.$router.push({ query: queryParams });
    },
    getFieldsVisible(name) {
      let result = this.fields.find((obj) => {
        return obj.field === name && obj.value === true;
      });
      return result;
    },
    getQueryParams() {
      const query = this.$route.query;
      this.setFilterFromQueryParam(query, ["id", "name", "key" , "monitoringSystemName"]);
      this.setCheckboxesArrFromQuery(query, []); // metricGroupsArr
      if (query.activities) {
        this.valuesForCheckboxes.activitiesArr[0] = query.activities;
      }
      if (query.testKpd) {
        this.valuesForCheckboxes.testKpdArr[0] = query.testKpd;
      }
    },
    cleanFilter() {
      this.filter = new MetricFilterImp();
      this.defaultValueFilter();
      this.setFilterFields();
      this.filterChange(new MetricFilterImp());
      this.$emit("filter", this.filter);
    },
    defaultValueFilter() {
      this.defaultValueCheckboxes([]);
    },
    setFilterFields() {
      this.fields
        .filter((v) => v.value === false)
        .forEach((f) => {
          if (f.field === "id") {
            this.filter.id = "";
          }
          if (f.field === "name") {
            this.filter.name = "";
          }
          if (f.field === "key") {
            this.filter.key = "";
          }
          if (f.field === "active") {
            this.valuesForCheckboxes.activitiesArr = [""];
          }
          if (f.field === "metricGroups") {
            this.valuesForCheckboxes.metricGroups = [""];
          }
        });
    },
  },
  data: () => ({
    metricGroup: "",
    filter: new MetricFilterImp(),
    valuesForCheckboxes: {
      metricGroups: [""],
      metricGroupsArr: [],
      activities: [
        { value: "", label: "Неважно" },
        {value: 'true', label: 'Да'},
        {value: 'false', label: 'Нет'}
      ],
      activitiesArr: [],
      testKpd: [
        { value: "", label: "Неважно" },
        {value: 'true', label: 'Да'},
        {value: 'false', label: 'Нет'}
      ],
      testKpdArr: [],
    },
    filterData: {},
  }),
  mounted() {
    Promise.all([this.getMetricGroups()]).then(() => {
      this.defaultValueFilter();
      this.getQueryParams();
      this.setFilterFields();
      this.applyFilter();
    });
  },
  setup() {
    return {
      locale,
    };
  },
});
