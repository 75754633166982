
import { defineComponent } from "vue";
import ListBase from "@/classes/ListBase.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";

export default defineComponent({
  mixins: [ListBase],
  components: {DeleteDialog},
  methods: {
    openDeleteDialog(id, name) {
      this.selectedId = id;
      this.$refs.delete.openDialog("связи", `связь с группой метрик ${name}`);
    }
  }
});
