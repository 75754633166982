
import { Util } from "@/components/utils/Util";
import Accordion from "@/components/ui/Accordion.vue";
import FilterBase from "@/classes/FilterBase.vue";
import locale from "ant-design-vue/es/time-picker/locale/ru_RU";
import {Field} from "@/types/fields";
import {defineComponent, PropType} from "vue";
import {MetricIndicatorFilterImp} from "@/components/classes/MetricIndicatorFilterImp";
import {MetricGroupApi} from "@/api/metric-group.api";
import {ClassifilesApi} from "@/api/classifires.api";

export default defineComponent({
  name: "MetricsIndicatorsFilter",
  components: {
    Accordion,
  },
  mixins: [FilterBase],
  props: {
    fields: Object as PropType<Field<unknown>[]>,
  },
  watch: {
    fields: {
      handler() {
        this.setFilterFields();
        this.applyFilter('filter');
      },
      deep: true
    }
  },
  methods: {
    changeFilter() {
      this.setQueryParams();
    },
    handleVisibleChange(e1) {
      console.log(e1);
    },
    onCheckLastValue(array, name) { // only last value
      Util.onCheckLastValue(array, name);
    },
    onCheckManyValues(array, name) { // many values
      Util.onCheckManyValues(array, name);
    },
    getMetricGroups(metricGroupName?) {
      const arrChecked = [];
      if (this.valuesForCheckboxes.metricGroupsArr.length > 0) {
        this.valuesForCheckboxes.metricGroupsArr.forEach(id => {
          const checked = this.valuesForCheckboxes.metricGroups.filter(mg => mg.id === id);
          if (checked.length > 0) {
            arrChecked.push(checked[0]);
          }
        });
      }
      const name = metricGroupName || this.metricGroup;
      MetricGroupApi.applyFilter(
          {
            name: name,
          },
          15,
          0,
      ).then(res => {
        this.valuesForCheckboxes.metricGroups = Util.transformToCheckboxes(res.data);
        if(arrChecked.length > 0) {
          arrChecked.forEach(c => {
            if (!this.valuesForCheckboxes.metricGroups.some(mg => mg.id === c.id)) {
              this.valuesForCheckboxes.metricGroups.push(c);
            }
          });
        }
      });
    },
    getScales(scaleName?) {
      const arrChecked = [];
      if (this.valuesForCheckboxes.scalesArr.length > 0) {
        this.valuesForCheckboxes.scalesArr.forEach(value => {
          const checked = this.valuesForCheckboxes.scales.filter(mg => mg.value === value);
          if (checked.length > 0) {
            arrChecked.push(checked[0]);
          }
        });
      }
      const name = scaleName || this.scale;
      ClassifilesApi.getMeasure(name)
          .then((res) => {
            this.valuesForCheckboxes.scales = Util.transformToCheckboxesAllName(res);
            if(arrChecked.length > 0) {
              arrChecked.forEach(c => {
                if (!this.valuesForCheckboxes.scales.some(mg => mg.value === c.value)) {
                  this.valuesForCheckboxes.scales.push(c);
                }
              });
            }
          });
    },
    applyFilter() {
      this.filter.activity = this.valuesForCheckboxes.activitiesArr[0] === ''
          ? null : this.valuesForCheckboxes.activitiesArr[0] === 'true';
      this.filter.metricGroupsIds = (this.valuesForCheckboxes.metricGroupsArr.length === 1
          && this.valuesForCheckboxes.metricGroupsArr[0] === '')
          ? [] : this.valuesForCheckboxes.metricGroupsArr;

      this.filter.scale = (this.valuesForCheckboxes.scalesArr.length === 1
          && this.valuesForCheckboxes.scalesArr[0] === '')
          ? "" : this.valuesForCheckboxes.scalesArr[0];
      this.filterChange(new MetricIndicatorFilterImp());
      this.$emit("filter", this.filter);
    },
    setQueryParams() {
      let queryParams: any = {};
      this.setQueryFromFilterParam(queryParams, ['id', 'name', 'codeName', 'description']);
      this.setQueryParamsArrDefault(queryParams, ['scalesArr']);
      this.$router.push({query: queryParams});
    },
    getFieldsVisible(name) {
      let result = this.fields.find(obj => {
        return obj.field === name && obj.value === true
      });
      return result;
    },
    getQueryParams(){
      const query = this.$route.query;
      this.setFilterFromQueryParam(query, ['id', 'name', 'codeName', 'description']);
      this.setCheckboxesArrFromQuery(query, ['scales']);
      if(query.activities) {
        this.valuesForCheckboxes.activitiesArr[0] = query.activities;
      }
    },
    cleanFilter() {
      this.filter = new MetricIndicatorFilterImp();
      this.defaultValueFilter();
      this.setFilterFields();
      this.filterChange(new MetricIndicatorFilterImp());
      this.$emit("filter", this.filter);
    },
    defaultValueFilter() {
      this.defaultValueCheckboxes([]);
    },
    setFilterFields() {
      this.fields.filter((v) => v.value === false)
          .forEach(f => {
            if(f.field === 'id') {
              this.filter.id = '';
            }
            if(f.field === 'name') {
              this.filter.name = '';
            }
            if(f.field === 'codeName') {
              this.filter.codeName = '';
            }
            if(f.field === 'description') {
              this.filter.description = '';
            }
            if(f.field === 'isActive') {
              this.valuesForCheckboxes.activitiesArr = [''];
            }
            if (f.field === 'scale') {
              this.valuesForCheckboxes.scalesArr = [''];
            }
          });
    },
  },
  data: () => ({
    metricGroup: '',
    scale: '',
    filter: new MetricIndicatorFilterImp(),
    valuesForCheckboxes: {
      metricGroups: '',
      metricGroupsArr: [],
      activities: [
        {value: '', label: 'Неважно'},
        {value: 'true', label: 'Да'},
        {value: 'false', label: 'Нет'}
      ],
      activitiesArr: [],
      scales: [],
      scalesArr: [],
    },
    filterData: {}
  }),
  mounted() {
    Promise.all([this.getMetricGroups(), this.getScales()])
        .then(() => {
          this.defaultValueFilter();
          this.getQueryParams();
          this.setFilterFields();
          this.applyFilter();
        });
  },
  setup() {
    return {
      locale
    }
  },
})
