
import {defineComponent} from "vue";
import CharacteristicBase from "../../classes/CharacteristicBase.vue";
import {getMetricIndicatorData} from "@/consts/fields/metrics-indicators.fields";
import {ClassifilesApi} from "@/api/classifires.api";

export default defineComponent({
  name: "MetricsIndicatorsCharacteristics",
  mixins: [CharacteristicBase],
  data: () => ({
    data: getMetricIndicatorData(),
    leaderList: [],
    validationFields:[
      {field: "name", validators: ["required"]}
    ],
  }),
  mounted() {
    this.getLeaderList();
  },
  methods: {
    getLeaderList() {
      ClassifilesApi.getMeasure(this.data.scale)
          .then((res) => {
            this.leaderList = res;
          });
    },
    selectLeader(value, option) {
      this.onSelect(value, option);
    },
    onSelect(value, option) {
      this.data[option.field] = value;
    },
  }
})
