
import {defineComponent, reactive} from "vue";
import {getMonitoringObjectsData, monitoringObjectsFields} from "@/consts/fields/monitoring-objects.fields";
import {Field} from "@/types/fields";
import {getMetricTypeData, metricFields} from "@/consts/fields/metrics.fields";
import {MetricListData} from "@/types/metric";
import {getMetricIndicatorData, metricIndicatorFields} from "@/consts/fields/metrics-indicators.fields";
import {MonitoringObjectsApi} from "@/api/monitoring-objects.api";
import {IndicatorApi} from "@/api/indicator.api";
import {MetricIndicatorListData} from "@/types/metric-indicator";
import {MetricIndicatorFilterImp} from "@/components/classes/MetricIndicatorFilterImp";
import {MetricApi} from "@/api/metric.api";
import MetricsFilter from "@/components/metrics/MetricsFilter.vue";
import {MetricFilterImp} from "@/components/classes/MetricFilterImp";
import {RolesApi} from "@/api/roles.api";
import {RolesListData} from "@/types/roles";
import {getRolesData, rolesFields} from "@/consts/fields/roles.fields";
import {RolesFilterImp} from "@/components/classes/RolesFilterImp";
import {GroupsListData} from "@/types/groups";
import {getGroupsData, groupsFields} from "@/consts/fields/groups.fields";
import {GroupsApi} from "@/api/groups.api";
import {GroupsFilterImp} from "@/components/classes/GroupsFilterImp";
import {executorUsersFields, getExecutorUsersData} from "@/consts/fields/executor-users.fields";
import {ExecutorUsersListData} from "@/types/executor-user";
import {ExecutorUsersApi} from "@/api/executor-users.api";
import {ExecutorUsersFilterImp} from "@/components/classes/ExecutorUsersFilterImp";
import {getIncidentData, incidentsFields} from "@/consts/fields/incidents.fields";
import {IncidentListData} from "@/types/incident";
import {IncidentsApi} from "@/api/incidents.api";
import {IncidentFilterImp} from "@/components/classes/IncidentFilterImp";
import EditForm from "@/components/base/EditForm.vue";
import {getMetricGroupData, metricGroupsFields} from "@/consts/fields/metrics-groups.fields";
import {MetricGroupListData} from "@/types/metric-group";
import {MetricGroupApi} from "@/api/metric-group.api";
import {MonitoringObjectListData} from "@/types/monitoring-object";
import MonitoringObjectsFilter from "@/components/monitoring-objects/MonitoringObjectsFilter.vue";
import {MonitoringObjectsFilterImp} from "@/components/classes/MonitoringObjectsFilterImp";
import {areasFields, getAreaData} from "@/consts/fields/areas.fields";
import {AreaListData} from "@/types/area";
import {AreaApi} from "@/api/area.api";
import {getServiceData, serviceFields} from "@/consts/fields/services.fields";
import {ServiceListData} from "@/types/service";
import {ServiceApi} from "@/api/service.api";
import {ServiceFilterImp} from "@/components/classes/ServiceFilterImp";
import {getListObjectMock} from "@/helpers/listObject";
import {equipmentTypesFields, getEquipmentTypesData} from "@/consts/fields/equipment-types.fields";
import {EquipmentTypeListData} from "@/types/equipment-type";
import {MonitoringObjectTypesApi} from "@/api/monitoring-object-types.api";
import {getSupportTeamData, supportTeamFields} from "@/consts/fields/support-team.fields";
import {SupportTeamListData} from "@/types/supportTeam";
import {SupportTeamApi} from "@/api/supportTeam.api";
import {EquipmentFilterImp} from "@/components/classes/EquipmentFilterImp";
import {SupportTeamsFilterImp} from "@/components/classes/SupportTeamsFilterImp";
// import {getServiceDeskRuleData, serviceDeskRulesFields} from "@/consts/fields/service-desk-rules.fields";
// import {ServiceDeskRuleListData} from "@/types/serviceDeskRule";
// import {ServiceDeskRuleApi} from "@/api/serviceDeskRule.api";
// import {ServiceDeskRulesFilterImp} from "@/components/classes/ServiceDeskRulesFilterImp";
import {calculationRulesFields, getCalculationRuleData} from "@/consts/fields/calculation-rule.fields";
import {CalculationRuleListData} from "@/types/сalculation-rule";
import {CalculationRuleApi} from "@/api/calculationRule.api";
import {CalculationRulesFilterImp} from "@/components/classes/CalculationRulesFilterImp";
import {
  calculationRulesHistoryFields,
  getCalculationRulesHistoryData
} from "@/consts/fields/calculation-rules-history.fields";
import {CalculationRulesHistoryListData} from "@/types/calculation-rules-history";
import {CalculationRulesHistoryFilterImp} from "@/components/classes/CalculationRulesHistoryFilterImp";
import {CalculationRuleHistoryApi} from "@/api/calculationRuleHistory.api";
import {departmentsFields, getDepartmentsData} from "@/consts/fields/departments.field";
import {DepartmentListData} from "@/types/department";
import {DepartmentsApi} from "@/api/departments.api";
import {DepartmentsFilterImp} from "@/components/classes/DepartmentsFilterImp";
import {counterpartyFields, getCounterpartyData} from "@/consts/fields/counterparty.fields";
import {CounterpartyListData} from "@/types/counterparty";
import {CounterpartyApi} from "@/api/counterparty.api";
import {CounterpartyFilterImp} from "@/components/classes/CounterpartyFilterImp";
import {currentUser} from "@/consts/currentUser";

export const GET_MONITORING_OBJECT_RELATED_OBJECT = () => ({
  ...getListObjectMock(),
  fields:  reactive(monitoringObjectsFields.slice() as Field<MonitoringObjectListData>[]),
  get: (...args) => MonitoringObjectsApi.applyFilter(args[0], args[1], args[2]),
  filter: new MonitoringObjectsFilterImp(),
  filterComponent: 'MonitoringObjectsFilter',
  listComponent: 'MonitoringObjectsList',
  routingUrl: '/monitoring/objects',
  type: 'monitoring-objects',
  prepareData: getMonitoringObjectsData()
})

export const GET_INDICATOR_RELATED_OBJECT = () => ({
  ...getListObjectMock(),
  fields:  reactive(metricIndicatorFields.slice() as Field<MetricIndicatorListData>[]),
  get: (...args) => IndicatorApi.applyFilter(args[0], args[1], args[2]),
  filter: new MetricIndicatorFilterImp(),
  filterComponent: 'MetricsIndicatorsFilter',
  listComponent: 'MetricIndicatorList',
  routingUrl: '/directory/metrics-indicators',
  type: 'metrics-indicators',
  prepareData: getMetricIndicatorData()
})

export const GET_AREA_RELATED_OBJECT = () => ({
  ...getListObjectMock(),
  fields:  reactive(areasFields.slice() as Field<AreaListData>[]),
  get: (...args) => AreaApi.applyFilter(args[0], args[1], args[2]),
  filter: new MetricIndicatorFilterImp(),
  filterComponent: 'AreasFilter',
  listComponent: 'AreaList',
  routingUrl: '/monitoring/areas',
  type: 'areas',
  prepareData: getAreaData()
})

export const GET_METRIC_RELATED_OBJECT = () => ({
  ...getListObjectMock(),
  fields:  reactive(metricFields.slice() as Field<MetricListData>[]),
  get: (...args) => MetricApi.applyFilter(args[0], args[1], args[2]),
  filter: new MetricFilterImp(),
  filterComponent: 'MetricsFilter',
  listComponent: 'MetricList',
  routingUrl: '/directory/metrics-type',
  type: 'metrics',
  prepareData: getMetricTypeData()
})

export const GET_ROLES_RELATED_OBJECT = () => ({
  ...getListObjectMock(),
  fields: reactive(rolesFields.slice() as Field<RolesListData>[]),
  get: (...args) => RolesApi.applyFilter(args[0], args[1], args[2]),
  filter: new RolesFilterImp(),
  filterComponent: 'RolesFilter',
  listComponent: 'RolesList',
  routingUrl: '/security/roles',
  type: 'roles',
  prepareData: getRolesData()
})


export const GET_GROUPS_RELATED_OBJECT = () => ({
  ...getListObjectMock(),
  fields: reactive(groupsFields.slice() as Field<GroupsListData>[]),
  get: (...args) => GroupsApi.applyFilter(args[0], args[1], args[2]),
  filter: new GroupsFilterImp(),
  filterComponent: 'GroupsFilter',
  listComponent: 'GroupsList',
  routingUrl: '/security/groups',
  type: 'groups',
  prepareData: getGroupsData()
})

export const GET_ROLE_OBJECTS_RELATED_OBJECT = () => ({
  ...getListObjectMock(),
  fields: reactive(groupsFields.slice() as Field<GroupsListData>[]),
  get: (...args) => RolesApi.getObjects(args[0], args[1], args[2]) ,
  filter: new GroupsFilterImp(),
  filterComponent: 'RoleObjectsFilter',
  listComponent: 'RoleObjectsList',
  routingUrl: '/security/role-objects',
  type: 'role-objects',
  prepareData: getGroupsData()
})

export const GET_USERS_RELATED_OBJECT = () => ({
  ...getListObjectMock(),
  fields: reactive(executorUsersFields.slice() as Field<ExecutorUsersListData>[]),
  get: (...args) => ExecutorUsersApi.applyFilter(args[0], args[1], args[2]),
  filter: new ExecutorUsersFilterImp(),
  filterComponent: 'ExecutorUsersFilter',
  listComponent: 'ExecutorUsersList',
  routingUrl: '/security/executor-users',
  type: 'executor-users',
  prepareData: getExecutorUsersData()
})

export const GET_INCIDENT_RELATED_OBJECT = () => ({
  ...getListObjectMock(),
  fields: reactive(incidentsFields.slice() as Field<IncidentListData>[]),
  get: (...args) => IncidentsApi.applyFilter(args[0], args[1], args[2]),
  filter: new IncidentFilterImp(),
  filterComponent: 'IncidentsFilter',
  listComponent: 'IncidentList',
  routingUrl: '/incidents',
  type: 'incidents',
  prepareData: getIncidentData()
})

export const GET_METRIC_GROUP_RELATED_OBJECT = () => ({
  ...getListObjectMock(),
  fields: reactive(metricGroupsFields.slice() as Field<MetricGroupListData>[]),
  get: (...args) => MetricGroupApi.applyFilter(args[0], args[1], args[2]),
  filter: new IncidentFilterImp(),
  filterComponent: 'MetricsGroupsFilter',
  listComponent: 'MetricGroupList',
  routingUrl: '/directory/metrics-groups',
  type: 'metric-groups',
  prepareData: getMetricGroupData()
})

export const GET_SERVICE_RELATED_OBJECT = () => ({
  ...getListObjectMock(),
  fields: reactive(serviceFields.slice() as Field<ServiceListData>[]),
  get: (...args) => ServiceApi.applyFilter(args[0], args[1], args[2]),
  filter: new ServiceFilterImp(),
  filterComponent: 'ServicesFilter',
  listComponent: 'ServiceList',
  routingUrl: '/services',
  type: 'services',
  prepareData: getServiceData()
})

export const GET_MONITORING_OBJECT_TYPE_RELATED_OBJECT = () => ({
  ...getListObjectMock(),
  fields: reactive(equipmentTypesFields.slice() as Field<EquipmentTypeListData>[]),
  get: (...args) => MonitoringObjectTypesApi.applyFilter(args[0], args[1], args[2]),
  filter: new EquipmentFilterImp(),
  filterComponent: 'EquipmentTypesFilter',
  listComponent: 'EquipmentTypesList',
  routingUrl: '/directory/equipment-types',
  type: 'equipment-types',
  prepareData: getEquipmentTypesData()
})
export const GET_SUPPORT_TEAM_RELATED_OBJECT = () => ({
  ...getListObjectMock(),
  fields: reactive(supportTeamFields.slice() as Field<SupportTeamListData>[]),
  get: (...args) => SupportTeamApi.applyFilter(args[0], args[1], args[2]),
  filter: new SupportTeamsFilterImp(),
  filterComponent: 'SupportTeamsFilter',
  listComponent: 'SupportTeamList',
  routingUrl: '/directory/support-teams',
  type: 'support-teams',
  prepareData: getSupportTeamData()
})
// export const GET_SERVICE_DESK_RULES = () => ({
//   ...getListObjectMock(),
//   fields: reactive(serviceDeskRulesFields.slice() as Field<ServiceDeskRuleListData>[]),
//   get: (...args) => ServiceDeskRuleApi.applyFilter(args[0], args[1], args[2]),
//   filter: new ServiceDeskRulesFilterImp(),
//   filterComponent: 'ServiceDeskRuleFilter',
//   listComponent: 'ServiceDeskRuleList',
//   routingUrl: '/',
//   type: 'rule',
//   prepareData: getServiceDeskRuleData()
// })
export const GET_DEPARTMENT_RELATED_OBJECT = () => ({
  ...getListObjectMock(),
  fields: reactive(departmentsFields.slice() as Field<DepartmentListData>[]),
  get: (...args) => DepartmentsApi.applyFilter(args[0], args[1], args[2]),
  filter: new DepartmentsFilterImp(),
  filterComponent: 'DepartmentsFilter',
  listComponent: 'DepartmentsList',
  routingUrl: '/directory/departments',
  type: 'department',
  prepareData: getDepartmentsData()
})
export const GET_COUNTERPARTY_RELATED_OBJECT = () => ({
  ...getListObjectMock(),
  fields: reactive(counterpartyFields.slice() as Field<CounterpartyListData>[]),
  get: (...args) => CounterpartyApi.applyFilter(args[0], args[1], args[2]),
  filter: new CounterpartyFilterImp(),
  filterComponent: 'CounterpartyFilter',
  listComponent: 'CounterpartyList',
  routingUrl: '/directory/counterparty',
  type: 'counterparty',
  prepareData: getCounterpartyData()
})
export const GET_CALCULATION_RULES = () => ({
  ...getListObjectMock(),
  fields: reactive(calculationRulesFields.slice() as Field<CalculationRuleListData>[]),
  get: (...args) => CalculationRuleApi.applyFilter(args[0], args[1], args[2]),
  filter: new CalculationRulesFilterImp(),
  filterComponent: 'CalculationRulesFilter',
  listComponent: 'CalculationRulesList',
  routingUrl: '/directory/calculation-rules/add',
  type: 'calculationRule',
  prepareData: getCalculationRuleData()
})

export const GET_CALCULATION_RULES_HISTORY = () => ({
  ...getListObjectMock(),
  fields:reactive(calculationRulesHistoryFields.slice() as Field<CalculationRulesHistoryListData>[]),
  get: (...args) => CalculationRuleHistoryApi.applyFilter(args[0], args[1], args[2]),
  filter: new CalculationRulesHistoryFilterImp(),
  filterComponent: 'CalculationRulesHistoryFilter',
  listComponent: 'CalculationRulesHistoryList',
  routingUrl: '/calculation-rules/',
  type: 'calculationRuleHistory',
  prepareData: getCalculationRulesHistoryData(),
  dto: 'CalculationRuleActiveChangelogDto'
})
export default defineComponent({
  components: {
    EditForm
  },
  data: () => ({
    activeKey: "1",
    currentUser,
    id: 0,
    sKey: null,
    vKey: null,
    sGroupKey: null,
    vGroupKey: null,
    relatedTitle: 'местоположения',
    selectorData: [],
    currentSelect: '' as  'Объекты' | 'Типы метрик' | 'Показатели' | 'Местоположения' | 'Роли' | 'Объекты ролей'
        | 'Группы' | 'Пользователи' | 'Группы метрик' | 'Услуги' | 'Типы объектов' | 'Команды поддержки' | 'Правила' |
        'Правила расчета' |'Котрагенты'|'Подразделения' | 'История правил расчета' | 'Правила расчета статусов',
    heightForRelatedObjectList: '332px',
    relatedObjects: {
      monitoringObjects: {
        ...GET_MONITORING_OBJECT_RELATED_OBJECT()
      },
      counterparty: {
        ...GET_COUNTERPARTY_RELATED_OBJECT()
      },
      metrics: {
        ...GET_METRIC_RELATED_OBJECT()
      },
      indicators: {
        ...GET_INDICATOR_RELATED_OBJECT()
      },
      areas: {
        ...GET_AREA_RELATED_OBJECT()
      },
      roles: {
        ...GET_ROLES_RELATED_OBJECT()
      },
      groups: {
        ...GET_GROUPS_RELATED_OBJECT()
      },
      users: {
        ...GET_USERS_RELATED_OBJECT()
      },
      incidents: {
        ...GET_INCIDENT_RELATED_OBJECT()
      },
      services: {
        ...GET_SERVICE_RELATED_OBJECT()
      },
      'equipment-types': {
        ...GET_MONITORING_OBJECT_TYPE_RELATED_OBJECT()
      },
      'support-teams': {
        ...GET_SUPPORT_TEAM_RELATED_OBJECT()
      },
      roleObjects: {
        ...GET_ROLE_OBJECTS_RELATED_OBJECT()
      },
      // 'rule': {
      //   ...GET_SERVICE_DESK_RULES()
      // },
      'calculationRule': {
        ...GET_CALCULATION_RULES
      },
      'department': {
        ...GET_DEPARTMENT_RELATED_OBJECT()
      },
      'calculationRuleHistory': {
        ...GET_CALCULATION_RULES_HISTORY
      }
    },
    type: '' as 'equipment' | 'services' | 'area' | 'metric-indicator' | 'metric' | 'role-objects'
        | 'monitoring-objects' | 'incidents' | 'metric-group' | 'equipment-types'| 'department' | 'counterparty'
        | 'support-teams' | 'rule' | 'rule_history ' | 'health-model-rule'| 'contracts' | 'calculated-status' ,
    keyForRelatedRequest: '',
    visibleSidebarTableSettings: false,
    visibleSidebarFilter: false,
    relatedObjectCannotBeAddedList: ["Показатели", "Типы метрик" , "Заявки"],
    isLoad: false,
    prepareData: null,
    countForList: 8,
    isFilterChanged: false,
  }),
  mounted() {
    this.keyForRelatedRequest = this.getKeyForRelatedRequest();
    this.getQueryParams();
  },
  methods: {
    /*TODO: вынести в общую задачу, из NSI BASE так же
    *  получать данные о типе из юрла */
    getKeyForRelatedRequest() {
      if(this.type === 'area') {
        return 'areaId';
      }
      if(this.type === 'service' || this.type === 'services') {
        return 'serviceId';
      }
      if (this.type === 'monitoring-objects') {
        if (this.currentRelatedObject.routingUrl === '/monitoring/objects')
          return 'parentId';
        else
          return 'monitoringObjectId';
      }
      if(this.type === 'metric-indicator') {
        return 'indicatorId';
      }
      if(this.type === 'group') {
        if (this.currentRelatedObject.routingUrl === '/security/executor-users')
          return 'groups'
        else
          return 'groupId';
      }
      if(this.type === 'role-objects') {
        if (this.currentRelatedObject.routingUrl === '/security/executor-users')
          return 'groups'
        else
          return 'groupId';
      }
      if(this.type === 'metric-group') {
        return 'metricGroupId';
      }
      if(this.type === 'metric') {
        return 'metricTypeId';
      }
      if(this.type === 'support-teams') {
        return 'supportTeamId';
      }
      if(this.type === 'equipment-types') {
        return 'monitoringObjectTypeId';
      }
      if(this.type === 'executor-users') {
        if (this.currentRelatedObject.routingUrl === '/security/roles' || this.currentRelatedObject.routingUrl === '/security/groups')
          return 'userId'
        else
          return 'executorId';
      }
      if(this.type === 'rule') {
        return 'dmnDecisionTableId';
      }
      if(this.type === 'department') {
        return 'departmentId';
      }
      if(this.type === 'contracts') {
        return 'contractId';
      }
      if(this.type === 'counterparty') {
        return 'counterpartyId';
      }
      if(this.type === 'health-model-rule') {
        return 'healthModelTemplateId';
      }
    },
    prepareDataForList() {
     if(this.currentRelatedObject
         && this.currentRelatedObject.prepareData != null) {
       for (let i = 0; i < this.countForList; i++) {
            this.currentRelatedObject.list
                .push(this.currentRelatedObject?.prepareData);
         }
     }
    },
    changeTab(key) {
      if (key == "3") {
        this.applyCurrentRelatedFilter();
      }
    },
    applyCurrentRelatedFilter() {
      this.keyForRelatedRequest = this.getKeyForRelatedRequest();
      this.prepareDataForList();
      this.isLoad = false;
      let id = this.$route.params.id;
      if (this.currentRelatedObject.routingUrl === '/security/executor-users' && this.keyForRelatedRequest !== 'departmentId') {
       id = [this.$route.params.id]
      }

      this.currentRelatedObject.get({
          ...this.currentRelatedObject.filter,
        [this.keyForRelatedRequest]: id,
      }, this.currentRelatedObject.size, this.currentRelatedObject.page)
        .then(res => {
          this.currentRelatedObject.list = [];
          this.currentRelatedObject.totalCount = res.totalCount;
          setTimeout(() => {
            this.currentRelatedObject.list = res.data;
          }, 1);
          this.isLoad = true;
        });
    },
    filterUpdate(filter) {
      this.currentRelatedObject.filter = Object.assign({}, filter);
      this.applyCurrentRelatedFilter();
    },
    filterChanged(changed) {
      this.isFilterChanged = changed;
    },
    change(activeKey) {
      this.$router.replace({ query: {...this.$route.query, tab: activeKey}});
    },
    getQueryParams() {
      const query = this.$route.query;
      if (query.tab) {
        this.activeKey = query.tab;
      }
      if (query.sel) {
        if (this.activeKey === '2') { this.currentSelect = this.selectorData[+query.sel].value;}
         else {
           if(this.selectorRuleData) {
             this.currentRuleSelect = this.selectorRuleData[+query.sel]?.value;
           }
           else {
             this.currentSelect = this.selectorData[+query.sel]?.value;
           }
         }
      }
    },
    onSelectRelated(value) {
        this.isLoad = false;
        this.prepareDataForList();
        const ind = this.selectorData.findIndex(d => d.value === value);
        this.$router.replace({ query: {...this.$route.query, sel: ind}});
    },
    updateList(value) {
      this.isLoad = false;
      let order = '';
      let column = '';
      if(value.columnKey === 'active') {
        column = 'activity'
      } else {
        column = value.columnKey;
      }
      if(value.order) {
        order = value.order === 'descend' ? 'desc': 'asc';
      } else {
        this.currentRelatedObject.filter.sortParams = [{sortFieldName: "id", direction: "desc"}];
        this.applyCurrentRelatedFilter();
        return;
      }
      let sortParams: any =
          {sortFieldName: column, direction: order};
      this.currentRelatedObject.filter.sortParams = [sortParams];
      this.applyCurrentRelatedFilter();
    },
    changeStateVisibleSidebarTableSettings() {
      this.visibleSidebarTableSettings = !this.visibleSidebarTableSettings;
    },
    changeStateVisibleSidebarFitler() {
      this.visibleSidebarFilter = !this.visibleSidebarFilter;
    },
    updatePage({ page, size }) {
      this.currentRelatedObject.size = size;
      this.currentRelatedObject.page = page - 1;
      this.currentRelatedObject.listPage = page;
      this.applyCurrentRelatedFilter();
    },
    routeToRelatedObject(relatedName = this.body.name) {
      this.$router.push({
        path: `${this.currentRelatedObject.routingUrl}`,
        query: {
          isRelated: `true`,
          hasBackButton: 'true',
          relatedId: this.$route.params.id,
          relatedName,
          relatedTitle: this.relatedTitle,
          from: this.type
        },
      });
    },
    routeToRelatedObjectAny(relatedName = this.body.name, relatedTitle, type) {
        this.$router.push({
        path: `${this.currentRelatedObject.routingUrl}`,
        query: {
          isRelated: `true`,
          hasBackButton: 'true',
          relatedId: this.$route.params.id,
          relatedName,
          relatedTitle: relatedTitle,
          from: type
        },
      });
    },
    cancel() {
      this.$router.go(-1);
    },
    hasAccessToSave(menuCode, childMenuCode, tabMenuCode?) {
      if ( childMenuCode) {
        if( tabMenuCode) {
          return !(this.currentUser.value.access.items.find((res) => res.menuCode === menuCode)
              .children.find(res => res.menuCode === childMenuCode)
              .children.find(res => res.menuCode === tabMenuCode).update &&
                this.currentUser.value.access.items.find((res) => res.menuCode === menuCode)
              .children.find(res => res.menuCode === childMenuCode).update)
        }
        else  {
          return this.currentUser.value.access.items.find((res) => res.menuCode === menuCode).children.find(res => res.menuCode === childMenuCode).update
        }
      } else {
        return this.currentUser.value.access.items.find((res) => res.menuCode === menuCode).update
      }
    },

    hasAccessToRead(menuCode, childMenuCode, tabMenuCode?) {
      if( !menuCode) {
         return  true;
      }
      if ( childMenuCode) {
        if( tabMenuCode) {
          console.log(this.currentUser.value.access.items.find((res) => res.menuCode === menuCode)
                  .children.find(res => res.menuCode === childMenuCode)
                  .children.find(res => res.menuCode === tabMenuCode).read, 'PROVERKA')
          return this.currentUser.value.access.items.find((res) => res.menuCode === menuCode)
              .children.find(res => res.menuCode === childMenuCode)
                  .children.find(res => res.menuCode === tabMenuCode).read &&
              this.currentUser.value.access.items.find((res) => res.menuCode === menuCode)
                  .children.find(res => res.menuCode === childMenuCode).read
        }
        else  {
          return this.currentUser.value.access.items.find((res) => res.menuCode === menuCode).children.find(res => res.menuCode === childMenuCode).read
        }
      } else {
        return this.currentUser.value.access.items.find((res) => res.menuCode === menuCode).read
      }
    },
    //accessParams = {module: 'menuCode', object: 'childMenuCode'}
    //tabMap = {'tabName': 'tabActiveKey', 'tabName2': 'tabActiveKey2'}
    determineActiveTab(accessParams, tabMap) {
      let tabAccess = [];

      // Проверяем доступ к разным вкладкам
      for (let tabKey in tabMap) {
        if (accessParams.object) {
          // Если object задан, используем его в качестве аргумента
          if (this.hasAccessToRead(accessParams.module, accessParams.object, tabKey)) {
            tabAccess.push(tabMap[tabKey]);
          }
        } else {
          // Если object не задан, передаём tab как childMenuCode
          if (this.hasAccessToRead(accessParams.module, tabKey)) {
            tabAccess.push(tabMap[tabKey]);
          }
        }
      }

      // Определяем активную вкладку
      let activeKey = this.activeKey || '1'; // значение по умолчанию, если activeKey не установлен
      if (this.activeKey && tabAccess.includes(this.activeKey)) {
        activeKey = this.activeKey;
      } else {
        // Если текущий activeKey недоступен или не задан, выбираем первую доступную вкладку
        activeKey = tabAccess.length > 0 ? tabAccess[0] : '1'; // или другое дефолтное значение
      }

      // Возвращаем активный ключ как строку
      return activeKey.toString();
    },
    hasAccessToDelete(menuCode, childMenuCode, tabMenuCode?) {
      if ( childMenuCode) {
        if( tabMenuCode) {
          console.log(this.currentUser.value.access.items.find((res) => res.menuCode === menuCode)
              .children.find(res => res.menuCode === childMenuCode)
              .children.find(res => res.menuCode === tabMenuCode), 'test')
          return this.currentUser.value.access.items.find((res) => res.menuCode === menuCode)
              .children.find(res => res.menuCode === childMenuCode)
              .children.find(res => res.menuCode === tabMenuCode).delete
        }
        else  {
          return this.currentUser.value.access.items.find((res) => res.menuCode === menuCode).children.find(res => res.menuCode === childMenuCode).delete
        }
      } else {
        return this.currentUser.value.access.items.find((res) => res.menuCode === menuCode).delete
      }
    },
    findCurrentSelectObject() {
      return this.selectorData[this.selectorData.findIndex(d => d.value === this.currentSelect)]
    }
  },
  computed: {
    currentRelatedObject() {
      if(this.currentSelect === 'Объекты') {
        return this.relatedObjects.monitoringObjects;
      }
      if(this.currentSelect === 'Группы метрик') {
        return this.relatedObjects['metric-groups'];
      }
      if(this.currentSelect === 'Типы метрик') {
        return this.relatedObjects.metrics;
      }
      if(this.currentSelect === 'Показатели') {
        return this.relatedObjects.indicators;
      }
      if(this.currentSelect === 'Роли') {
        return this.relatedObjects.roles;
      }
      if(this.currentSelect === 'Группы') {
        return this.relatedObjects.groups;
      }
      if(this.currentSelect === 'Объекты ролей') {
        return this.relatedObjects.roleObjects;
      }
      if(this.currentSelect === 'Пользователи' || this.currentSelect === 'Сотрудники') {
        return this.relatedObjects.users;
      }
      if(this.currentSelect === 'Заявки') {
        return this.relatedObjects.incidents;
      }
      if(this.currentSelect === 'Местоположения') {
        return this.relatedObjects.areas;
      }
      if(this.currentSelect === 'Услуги') {
        return this.relatedObjects.services;
      }
      if(this.currentSelect === 'Контрагенты') {
        console.log(this.relatedObjects,'Контрагенты')
        return this.relatedObjects.counterparty;
      }
      if(this.currentSelect === 'Подразделения') {
        return this.relatedObjects.department;
      }
      if(this.currentSelect === 'Типы объектов') {
        return this.relatedObjects['equipment-types'];
      }
      if(this.currentSelect === 'Команды поддержки') {
        return this.relatedObjects['support-teams'];
      }
      if(this.currentSelect === 'Правила') {
        return this.relatedObjects['rule'];
      }
      return this.relatedObjects.area;
    },
    canAddRelation() {
      return !this.relatedObjectCannotBeAddedList.find(_  => this.currentSelect == _);
    },
  }
});
