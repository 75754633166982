
import { Util } from "@/components/utils/Util";
import {mixins, Options, Vue} from "vue-class-component";
import Accordion from "@/components/ui/Accordion.vue";
import FilterBase from "@/classes/FilterBase.vue";
import Super from "@/classes/FilterBase.vue";
import locale from "ant-design-vue/es/time-picker/locale/ru_RU";
import {Field} from "@/types/fields";
import {defineComponent, PropType} from "vue";
import {MetricGroupFilterImp} from "@/components/classes/MetricGroupFilterImp";

export default defineComponent({
  name: "MetricsGroupsFilter",
  components: {
    Accordion,
  },
  mixins: [FilterBase],
  props: {
    fields: Object as PropType<Field<unknown>[]>,
  },
  watch: {
    fields: {
      handler() {
        this.setFilterFields();
        this.applyFilter('filter');
      },
      deep: true
    }
  },
  methods: {
    changeFilter() {
      this.setQueryParams();
    },
    handleVisibleChange(e1) {
      console.log(e1);
    },
    onCheckLastValue(array, name) {
      Util.onCheckLastValue(array, name);
    },
    applyFilter() {
      this.filter.activity = this.valuesForCheckboxes.activitiesArr[0] === ''
          ? null : this.valuesForCheckboxes.activitiesArr[0] === 'true';
      this.filterChange(new MetricGroupFilterImp());
      this.$emit("filter", this.filter);
    },
    setQueryParams() {
      let queryParams: any = {};
      this.setQueryFromFilterParam(queryParams, ['id', 'name']);
      this.setQueryParamsArrDefault(queryParams, []);
      this.$router.push({query: queryParams});
    },
    getFieldsVisible(name) {
      let result = this.fields.find(obj => {
        return obj.field === name && obj.value === true
      });
      return result;
    },
    getQueryParams(){
      const query = this.$route.query;
      this.setFilterFromQueryParam(query, ['id', 'name']);
      this.setCheckboxesArrFromQuery(query, []);
      if(query.activities) {
        this.valuesForCheckboxes.activitiesArr[0] = query.activities;
      }
    },
    cleanFilter() {
      this.filter = new MetricGroupFilterImp();
      this.defaultValueFilter();
      this.setFilterFields();
      this.filterChange(new MetricGroupFilterImp());
      this.$emit("filter", this.filter);
    },
    defaultValueFilter() {
      this.defaultValueCheckboxes([]);
    },
    setFilterFields() {
      this.fields.filter((v) => v.value === false)
          .forEach(f => {
            if(f.field === 'id') {
              this.filter.id = '';
            }
            if(f.field === 'name') {
              this.filter.name = '';
            }
            if(f.field === 'active') {
              this.valuesForCheckboxes.activitiesArr = [''];
            }
          });
    },
  },
  data: () => ({
    filter: new MetricGroupFilterImp(),
    valuesForCheckboxes: {
      activities: [
        {value: '', label: 'Неважно'},
        {value: 'true', label: 'Да'},
        {value: 'false', label: 'Нет'}
      ],
      activitiesArr: [],
    },
    filterData: {}
  }),
  mounted() {
    this.defaultValueFilter();
    this.getQueryParams();
    this.setFilterFields();
    this.applyFilter();
  },
  setup() {
    return {
      locale
    }
  },
})
