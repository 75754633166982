
import {Options, Vue} from "vue-class-component";
import NSIBase from "@/classes/NSIBase.vue";
import TableSettings from "@/components/base/TableSettings.vue";
import Accordion from "@/components/ui/Accordion.vue";
import FileManagerFilter from "@/components/file-manager/FileManagerFilter.vue";
import {FileManagerListData} from "@/types/file-manager";
import {FileManagerFilterImp} from "@/components/classes/FileManagerFilterImp";
import {fileManagerDtoSettings$, setVisibleToFieldsInTable} from "@/consts/settings";
import {takeWhile} from "rxjs/operators";
import {fileManagerFields} from "@/consts/fields/file-manager.fields";
import {Field} from "@/types/fields";
import {FileManagerApi} from "@/api/file-manager.api";
import FileManagerList from "@/components/file-manager/FileManagerList.vue";
import OperatorList from "@/components/operators/OperatorList.vue";

@Options({
  components: {
    OperatorList,
    TableSettings,
    Accordion,
    FileManagerList,
    FileManagerFilter
  },
  mixins: [NSIBase],
  data: () => {
    return {
      list: [] as FileManagerListData[],
      selectedList: [],
      search: "",
      isLoadingExcel: false,
      visibleSidebarFilter: false,
      visibleSidebarTableSettings: false,
      totalCount: 0,
      size: 20,
      page: 1,
      incidentKeys: "" as keyof FileManagerListData,
      fields: null,
      filter: new FileManagerFilterImp(),

    }
  },
  mounted() {
    fileManagerDtoSettings$.pipe(
        takeWhile(() => !this.fields),
    )
        .subscribe(settings => {
          if (!settings) {
            return;
          }
          if (!settings.fields) {
            this.fields = settings;
            return;
          }
          this.fields
              = setVisibleToFieldsInTable(fileManagerFields, settings.fields) as Field<FileManagerListData>[];
        })
  },
  watch: {
    search: {
      handler() {
        this.commonSearch();
      },
      deep: true
    },
  },
  methods: {
    changeStateVisibleSidebarFitler() {
      this.visibleSidebarFilter = !this.visibleSidebarFilter;
    },
    toggleSuffix() {
      this.search = '';
    },
    changeStateVisibleSidebarTableSettings() {
      this.visibleSidebarTableSettings = !this.visibleSidebarTableSettings;
    },
    applyFilter(from?: 'filter' | unknown) {
      if(from !== 'filter') {
        if (this.search !== '')
          this.applyFilterRequest$.next('containsValue');
        else
          this.debounceApplyFilter();
        return;
      }
      this.page = 0;
      this.listPage = 1;

      this.debounceApplyFilter();
    },
    debounceApplyFilter(){
      this.applyFilterRequest$.next();
    },
    updateList(value) {
      let order = '';
      let column = value.columnKey;

      if(value.order) {
        order = value.order === 'descend' ? 'desc': 'asc';
      } else {
        this.filter.sortParams = [{sortFieldName: "id", direction: "asc"}];
        this.applyFilter();
        return;
      }
      let sortParams: any =
          {sortFieldName: column, direction: order};
      this.filter.sortParams = [sortParams];
      this.applyFilter();
    },
    resultMethod(res) {
      this.list = res.data;
      this.totalCount = res.totalCount;
      this.isLoad = true;
      console.log(this.list , 'LIST')
    },
    searchMethod(_) {
      const filter = this.defineFilterData;
      filter[this.exceptKey] = this.$route.query.relatedId;
      return FileManagerApi.applyFilter(_ ? {containsValue: this.search, sortParams: this.filter.sortParams}
          : this.filter, this.size, this.page);
    }
  }
})
export default class FileManager extends Vue {
  list!: FileManagerListData[];
  search: string;
  filter: FileManagerFilterImp;
}
