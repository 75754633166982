
import {defineComponent} from "vue";
import moment from "moment";
import {ClickhouseApi} from "@/api/clickhouse";
import Graphic from "@/components/base/Graphic.vue";
export type Period = "day" | "month" | "week" | "year" | "quarter" | "custom";
export default defineComponent({
  data: () => ({
    selectedPeriod: "day" as Period,
    period: [],
    amount: 1
  }),
  mounted() {
    if (this.$route.query.dateStart && this.$route.query.dateEnd) {
      this.period = [moment(Number(this.$route.query.dateStart)), moment(Number(this.$route.query.dateEnd))];
      this.handleChangePeriod(this.period);
    } else {
      this.period = this.getDefaultPeriod(this.selectedPeriod);
    }
  },
  methods: {
    getDefaultPeriod(period: Period) {
      if(period === 'day') {
        return [moment().subtract(this.amount, "day"), moment()]
      }
      if(period === 'month') {
        return [moment().subtract(this.amount, "month"), moment()]
      }
      if(period === 'year') {
        return [moment().subtract(this.amount, "year"), moment()]
      }
      if(period === 'quarter') {
        return [moment().subtract(this.amount, "quarter"), moment()]
      }
      return [moment().subtract(this.amount, "weeks"), moment()]
    },
    handleChangePeriod(ev) {
      const gap = moment(ev[1]).diff(moment(ev[0]), 'days')/5;
      if(gap === 1 && !this.isCurrentPeriod('day')) {
        this.setPeriod('day');
        return;
      }
      if(gap === 7 && !this.isCurrentPeriod('week')) {
        this.setPeriod('week');
        return;
      }
      if((gap === 365 || gap === 366) && !this.isCurrentPeriod('year')) {
        this.setPeriod('year');
        return;
      }
      if((gap === 182.5 || gap === 183) && !this.isCurrentPeriod('quarter')) {
        this.setPeriod('quarter');
        return;
      }
      if(gap !== 1 && gap !== 7 && gap !== 365 && gap !== 366) {
        this.setPeriod(null)
      }
    },
    setPeriod(period) {
      if(!period) {
        this.selectedPeriod = null;
      }else {
        this.selectedPeriod = period;
        this.period = this.getDefaultPeriod(this.selectedPeriod);
      }
      this.$router.replace({query: {
          ...this.$route.query,
          dateStart: moment(this.period[0]).valueOf(),
          dateEnd: moment(this.period[1]).valueOf(),
        }})
    },
    isCurrentPeriod(period) {
      return this.selectedPeriod === period;
    },
  },
})
