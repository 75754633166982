
import {defineComponent} from "vue";
import CharacteristicBase from "@/classes/CharacteristicBase.vue";
import {getMeasureData} from "@/consts/fields/measure.fields";
import {ClassifilesApi} from "@/api/classifires.api";

export default defineComponent({
  name: "MeasureCharacteristics",
  mixins: [CharacteristicBase],
  data: () => ({
    data: getMeasureData(),
    measureTypeList: [],
    validationFields:[
      {field: "name", validators: ["required"]},
      {field: "scale", validators: ["required"]},
      {field: "measureType", validators: ["required"]},
    ],
  }),
  methods: {
    getMeasureType() {
      ClassifilesApi.getMeasureType().then(res => {
        this.measureTypeList = res;
      })
    },
    onSelectMeasureType(value, option) {
      delete option.field
      option.name = option.value
      delete option.value
      this.data.measureType = option;
    }
  },
  mounted() {
    this.getMeasureType();
    this.getCRUDSettings(97);
  }
})
