
import {defineComponent} from "vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import EditForm from "@/components/base/EditForm.vue";
import ViewBase from "@/classes/ViewBase.vue";
import TableSettings from "@/components/base/TableSettings.vue";
import {GroupsApi} from "@/api/groups.api";
import GroupCharacteristics from "@/components/groups/GroupCharacteristics.vue";
import RolesList from "@/components/roles/RolesList.vue";
import RolesFilter from "@/components/roles/RolesFilter.vue";
import ExecutorUsersList from "@/components/executor-users/ExecutorUsersList.vue";
import ExecutorUsersFilter from "@/components/executor-users/ExecutorUsersFilter.vue";
import {suffix} from "@/consts/nameSuff";

export default defineComponent({
  name: "GroupView",
  components: {
    RolesList, RolesFilter,
    ExecutorUsersList, ExecutorUsersFilter,
    GroupCharacteristics,
    EditForm,
    DeleteDialog,
    TableSettings
  },
  mixins: [ViewBase],
  data: () => ({
    list: [],
    body: null,
    selectorData: [
      {value: 'Роли', menuCode: 'security', childMenuCode: 'roles'},
      {value: 'Пользователи', menuCode: 'security', childMenuCode: 'users'}
    ],
    currentSelect: 'Роли',
    type: "group",
    visibleSidebarFilter: false,
    visibleSidebarTableSettings: false,
  }),
  mounted() {
    this.id = this.$route.params.id;
    this.sKey = 'groupId';
    this.vKey = this.currentSelect === 'Пользователи' ? [this.id] :this.id;
    this.sGroupKey = 'groups';
    this.vGroupKey = this.currentSelect === 'Пользователи' ? [this.id] :this.id;
    this.getById();

    const accessParams = { module: 'security', object: 'groups' };
    const tabMap = {
      'tab_common_information_groups': '1',
      'tab_relation_groups': '3',
    };
    this.activeKey = this.determineActiveTab(accessParams, tabMap);
  },
  beforeRouteLeave(to, from, next) {
    suffix.next(null);
    if (!this.$refs.characteristicsa?.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    onSelect(value) {
      const ind = this.selectorData.findIndex(sd => sd.value === value);
      this.$router.replace({ query: {...this.$route.query, sel: ind}});
    },
    openDeleteDialog() {
      this.$refs.delete.openDialog("группы", `группу ${this.body.name}`);
    },
    getById() {
      if (this.$refs.characteristics)
        this.$refs.characteristics.clean();

      GroupsApi.getById(this.id)
          .then(res => {
            this.body = res;
            suffix.next(' ' + res.name);
            // this.vGroupKey = [this.body.name];
          }).finally(() => {
        this.$refs.characteristics.cleanWarning();
      });
    },
    save() {
      if(this.$refs.characteristics.validate()) {
        return;
      }
      const characteristic = this.$refs.characteristics.formatCharacteristicsCopy();
      GroupsApi.save(characteristic).then(() => {
        this.getById();
      })
    },
    deleteObject() {
      GroupsApi.delete(this.id).then(res => {
        this.$router.go(-1);
      })
    }
  }
})
