
import {computed, defineComponent, PropType, ref, toRefs, watch} from "vue";
import {Field} from "@/types/fields";
import {currentUser} from "@/consts/currentUser";
import locale from "ant-design-vue/es/time-picker/locale/ru_RU";


export const listEmits = ["update:page", "update:size", "changeSorter", "changePage"]


interface SortParams {
  sortFieldName: string;
  direction: "asc" | "desc";
}

export default defineComponent({
  props: {
    height: {default: '300px'},
    list: Object as PropType<unknown[]>,
    basePage: {default: 'counterparty'},
    totalCount: Number,
    scroll: { default: {y: "calc(100vh - 300px)" ,x:"calc(100vw - 500px)"} },
    fields: Object as PropType<Field<unknown>[]>,
    size: {default: 0},
    page: {default: 0},
    isDeleteButton: {default: false},
    isDownload: {default: false},
    relatedId: {default: 0},
    isLoad: {default: false},
    typeRelate: {default: null},
    showLessItems: {default: false},
    hasPagination: {default: true}
  },
  data: () => ({
    checkedList: [],
    currentUser,
    selectedArray:[],
    id: Math.floor(Math.random() * 999),
    total: 0,
    currentPage: 1,
    currentSize: 20,
    paginationConfig: {
      pageSizeOptions: ["20", "50", "100"],
    },
    sortParams: {},
  }),
  computed: {
    filteredFields() {
      return this.fields.filter((_) => _.value);
    },
  },
  mounted() {
    this.setOrderPagination();
  },
  watch: {
    totalCount() {
      this.total = this.$props.totalCount;
    },
    page() {
      this.currentPage = this.$props.page;
    },
    size() {
      this.currentSize = this.$props.size;
    },
  },
  emits: ["update:page", "update:size", "changeSorter", "changePage", "delete"],
  methods: {
    deleteObjectEmit() {
      this.$emit('delete', this.selectedId)
    },
    hasAccessToSave(menuCode, childMenuCode) {
      if ( childMenuCode) {
        return !this.currentUser.value.access.items.find((res) => res.menuCode === menuCode).children.find(res => res.menuCode === childMenuCode).update
      } else {
        return !this.currentUser.value.access.items.find((res) => res.menuCode === menuCode).update
      }
    },
    selectRelatedObject(e , id) {
      if (e.target.checked) {
        this.checkedList.push(id)
        this.$emit('enlarge-text',this.checkedList)
      }
      else {
        this.checkedList.splice(this.checkedList.indexOf(id) , 1)
        this.$emit('enlarge-text',this.checkedList)
      }
    },
    hasAccessToLink(menuCode, childMenuCode, tabMenuCodes = []) {
      const isAccessible = tabMenuCodes.some(tabMenuCode => {
        const tabMenu = this.currentUser.value.access.items.find(res => res.menuCode === menuCode)
          .children.find(res => res.menuCode === childMenuCode)
          .children.find(res => res.menuCode === tabMenuCode);

        console.log(tabMenu?.read, 'PROVERKA');

        return tabMenu?.read;
      });

      const isChildMenuReadable = this.currentUser.value.access.items.find(res => res.menuCode === menuCode)
        .children.find(res => res.menuCode === childMenuCode)
        .read;

      return isAccessible && isChildMenuReadable;
    },
    checkSelection( navigateUrl, menuCode?, childMenuCode?, tabMenuCodes = [] ) {
      if (menuCode) {
        if (this.hasAccessToLink(menuCode, childMenuCode, tabMenuCodes)) {
          this.toRoute(navigateUrl);
        }
      } else {
        this.toRoute(navigateUrl);
      }
    },
    toRoute(navigateUrl) {
      if (window.getSelection().toString()) {
        return;
      }
      this.$router.push(navigateUrl);
    },
    onChange(page, size) {
      this.$emit("changePage", { page, size });
    },
    sort(a, b) {
      return null; //a[name] - b[name];
    },
    setOrderPagination() {
      if(this.hasPagination) {
        const currentEl = document.querySelector(`#list_${this.id}`);
        const countDefault = currentEl.querySelector<HTMLElement>('.ant-pagination-options');
        if(countDefault) {
          countDefault.style.display = 'none';
        }
        const elTableCount = currentEl.querySelector<HTMLElement>('#table-count');
        const pagination = currentEl.querySelector('ul.ant-pagination');
        pagination.appendChild(elTableCount);
        elTableCount.style.visibility = 'visible';
      }
    },
    changeCount(count) {
      console.log(count);
      this.onChange(this.currentPage, count);
    },
    createSelectedArray(e) {
      if (e.checked) {
        this.selectedArray.push(e.id)
      }
      else {
        this.selectedArray.splice(this.selectedArray.indexOf(e.id) , 1)
      }
      console.log(this.selectedArray)
      console.log(this.selectedArray.includes(e.id))
    },
    selectAll(e) {
      if (e.target.checked) {
        this.list.forEach((res) => res.checked = true)
        this.checkedList = this.list.map(res => res.id)
        this.selectedArray = this.list.map(res => res.id)
        this.$emit('enlarge-text',this.checkedList)
      }
      else {
        this.list.forEach((res) => res.checked = false)
        this.checkedList = [];
        this.selectedArray = [];
        this.$emit('enlarge-text',this.checkedList)
      }
    },
    handleTableChange(
        pag: { pageSize: number; current: number },
        filters: any,
        sorter: any
    ) {
      console.log(sorter)
      this.$emit("changeSorter", sorter);
    },
  },
})
