
import {defineComponent} from "vue";
import CharacteristicBase from "../../classes/CharacteristicBase.vue";
import {getMetricGroupData} from "@/consts/fields/metrics-groups.fields";

export default defineComponent({
  name: "MetricsGroupsCharacteristics",
  mixins: [CharacteristicBase],
  data: () => ({
    data: getMetricGroupData(),
    validationFields:[
      {field: "name", validators: ["required"]}
    ],
  }),
  methods: {
    selectLeader(value, option) {
      this.onSelect(value, option);
    },
    onSelect(value, option) {
      this.data[option.field] = value;
    },
  },
  mounted() {
    this.getCRUDSettings(64)
  }
})
