
import {defineComponent} from "vue";
import CharacteristicBase from "@/classes/CharacteristicBase.vue";
import {getContractsData} from "@/consts/fields/contracts.fields";
import {ClassifilesApi} from "@/api/classifires.api";

export default defineComponent ({
  name: "ContractsCharacteristics",
  mixins: [CharacteristicBase],
  data: () => ({
    data: getContractsData(),
    validationFields:[
      {field: "name", validators: ["required"]},
      {field: "number", validators: ["required"]},
    ],
    statusList: []
  }),
  methods: {
    getContractsList() {
      ClassifilesApi.getContractsStatus().then((res) => {
        this.statusList = res;
      })
    }
  },
  mounted() {
    this.getContractsList();
    this.getCRUDSettings(67);
  }
})
