
import { defineComponent } from "vue";
import CharacteristicBase from "../../classes/CharacteristicBase.vue";
import { getCollectionAgentData } from "@/consts/fields/collection-agents.fields";
import moment from "moment";
import { CollectionAgentsApi } from "@/api/collection-agents.api";
import * as cron from "cron-validator";
import {currentUser} from "@/consts/currentUser";

export default defineComponent({
  name: "CollectionAgentCharacteristics",
  mixins: [CharacteristicBase],
  data: () => ({
    showPassword: "",
    password: "",
    currentUser,
    data: getCollectionAgentData(),
    daysOfWeek: [
      { value: "ПН", number: 0 },
      { value: "ВТ", number: 1 },
      { value: "СР", number: 2 },
      { value: "ЧТ", number: 3 },
      { value: "ПТ", number: 4 },
      { value: "СБ", number: 5 },
      { value: "ВС", number: 6 },
    ],
    gapDays: [{ value: "день" }, { value: "неделя" }, { value: "месяц" }],
    cronValueIndex: 0,
    cronForms: {
      everyMinute: {
        minute: null,
      },
      everyDay: {
        time: null,
      },
      everyWeek: {
        time: null,
        dayOfWeek: null,
      },
      everyMonth: {
        time: null,
        month: null,
      },
      cron: "",
    },
    validationFields: [
      { field: "name", validators: ["required"] },
      { field: "cronCustomValidation", validators: ["required"] },
    ],
    adapterTypes: [],
  }),
  mounted() {
    this.getAdapterTypes();
    this.getCRUDSettings(87)
  },
  methods: {
    hasAccessToSave(menuCode, childMenuCode) {
      if ( childMenuCode) {
        return !this.currentUser.value.access.items.find((res) => res.menuCode === menuCode).children.find(res => res.menuCode === childMenuCode).update
      } else {
        return !this.currentUser.value.access.items.find((res) => res.menuCode === menuCode).update
      }
    },
    checkCronValueIndexErrors() {
      let hasErrors = false;
      const cronValues = [
        {
          cronIndex: 0,
          cronFormValues: [
            {
              value: this.cronForms.everyMinute.minute,
              ref: 'everyMinute'
            }
          ]
        },
        {
          cronIndex: 1,
          cronFormValues: [
            {
              value: this.cronForms.everyDay.time,
              ref: 'everyDay'
            }
          ]
        },
        {
          cronIndex: 2,
          cronFormValues: [
            {
              value: this.cronForms.everyWeek.time,
              ref: 'everyWeekTime'
            },
            {
              value: this.cronForms.everyWeek.day,
              ref: 'everyWeekDay'
            },
          ]
        },
        {
          cronIndex: 3,
          cronFormValues: [
            {
              value: this.cronForms.everyMonth.month,
              ref: 'everyMonthMonth'
            },
            {
              value: this.cronForms.everyMonth.time,
              ref: 'everyMonthTime'
            },
          ]
        },
        {
          cronIndex: 4,
          cronFormValues: [
            {
              value: this.cronForms.cron,
              ref: 'cron'
            },
          ]
        },
      ]

      cronValues.forEach(cronValue => {
        cronValue.cronFormValues.forEach(cronFormValue => {
          if(cronFormValue.value || this.cronValueIndex !== cronValue.cronIndex) {
            this.$refs[cronFormValue.ref].$el.parentNode.classList.remove('has-error')
          } else if(this.cronValueIndex === cronValue.cronIndex && this.formTouched) {
            this.$refs[cronFormValue.ref].$el.parentNode.classList.add('has-error');
            hasErrors = true;
          }
        })
      })
      return hasErrors;
    },
    customValidation(field = "", event?, validationField?) {
      if (field !== "cronCustomValidation") {
        return false;
      }
      if(!this.cronValueIndex && this.cronValueIndex !== 0) {
        this.cronValueIndex = 0;
      }

      if (this.checkCronValueIndexErrors() || !cron.isValidCron(this.defineCronValue())) {
        if (!validationField) {
          this.validationFields.push({
            field: "cronCustomValidation",
            touched: false,
            validators: [],
          });
        }
        this.customErrors.push({
          field: "cronCustomValidation",
          touched: false,
          validators: [],
        })
      }
      if (!cron.isValidCron(this.defineCronValue())) {
        this.$notification.open({
          message: "Убедитесь в правильности введенного расписания запуска",
          style: {
            width: "330px",
            marginLeft: `${400 - 330}px`,
            fontSize: "14px",
          },
          placement: "bottomRight",
          duration: 3,
        });
        return false;
      }
      this.validationFields = this.validationFields.filter(
          (validationField) => validationField.field !== field
      );
      this.customErrors = this.customErrors.filter(
          (validationField) => validationField.field !== field
      );
    },
    handleChangeCronEvent(event, ref) {
      let data
      if (event && event.target) {
        data = event.target.value;
      }
      if (typeof event === "string" || typeof event === "number") {
        data = event;
      }
      if(!data) {
        this.$refs[ref].$el.parentNode.classList.add("has-error");
        return
      }
      this.$refs[ref].$el.parentNode.classList.remove("has-error");
    },
    stopBubble(event) {
      console.log(event);
      event.stopPropagation();
      event.preventDefault;
    },
    getAdapterTypes() {
      CollectionAgentsApi.getAdapterType().then((res) => {
        this.adapterTypes = res;
      });
    },
    startAgent() {
      CollectionAgentsApi.agentStart({agentId:this.data.id}).then((res) => {
        console.log(321)
      })
    },
    stopAgent() {
      CollectionAgentsApi.agentStop({agentId:this.data.id}).then((res) => {
        console.log(321)
      })
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    defineCronValueIndex() {
      if (!this.data.cron) {
        return 0;
      }
      const cronArray = this.data.cron.split(" ");
      const minute = cronArray[0];
      const hour = cronArray[1];
      const day = cronArray[2];
      const month = cronArray[3];
      const dayOfWeek = cronArray[4];
      // раз в несколько минут
      if (
        minute !== "*" &&
        hour === "*" &&
        day === "*" &&
        month === "*" &&
        dayOfWeek === "*"
      ) {
        this.cronForms.everyMinute.minute = minute;
        return 0;
      }
      // каждый день _:_
      if (
        minute !== "*" &&
        hour !== "*" &&
        day === "*" &&
        month === "*" &&
        dayOfWeek === "*"
      ) {
        this.cronForms.everyDay.time = moment({ hour, minute });
        return 1;
      }
      // Каждую неделю в деньнедели в _:_
      if (
        minute !== "*" &&
        hour !== "*" &&
        day === "*" &&
        month === "*" &&
        dayOfWeek !== "*" &&
        this.daysOfWeek.find(({ number }) => number === +dayOfWeek)
      ) {
        this.cronForms.everyWeek.time = moment({ hour, minute });
        this.cronForms.everyWeek.dayOfWeek = this.daysOfWeek.find(
          ({ number }) => number === +dayOfWeek
        )?.value;

        return 2;
      }
      // Каждую месяц _ числа в _:_
      if (
        minute !== "*" &&
        hour !== "*" &&
        day !== "*" &&
        month === "*" &&
        dayOfWeek === "*"
      ) {
        this.cronForms.everyMonth.time = moment({ hour, minute });
        this.cronForms.everyMonth.month = day;
        return 3;
      }
      this.cronForms.cron = this.data.cron;
      return 4;
    },
    defineCronValue() {
      if (this.cronValueIndex === 0) {
        return `${this.cronForms.everyMinute.minute} * * * *`;
      }
      if (this.cronValueIndex === 1) {
        return `${this.cronForms.everyDay.time?.format(
          "mm"
        )} ${this.cronForms.everyDay.time?.format("HH")} * * *`;
      }
      if (this.cronValueIndex === 2) {
        const numberDayOfWeek = this.daysOfWeek.find(
          (dayOfWeek) => dayOfWeek.value === this.cronForms.everyWeek.dayOfWeek
        ).number;
        return `${this.cronForms.everyWeek.time?.format(
          "mm"
        )} ${this.cronForms.everyWeek.time?.format(
          "HH"
        )} * * ${numberDayOfWeek}`;
      }
      if (this.cronValueIndex === 3) {
        return `${this.cronForms.everyMonth.time?.format(
          "mm"
        )} ${this.cronForms.everyMonth.time?.format("HH")} ${
          this.cronForms.everyMonth.month
        } * *`;
      }
      if (this.cronValueIndex === 4) {
        return this.cronForms.cron;
      }
    },
  },
  watch: {
    cronValueIndex: {
      handler() {
        this.checkCronValueIndexErrors();
      }
    },
    data: {
      handler() {
        this.cronValueIndex = this.defineCronValueIndex();
      },
      deep: true,
    },
  },
});
